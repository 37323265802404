import { Button, styled } from "@mui/material";

const OperationButtonOutlined = styled(Button)(() => ({
    marginTop: "7px",
    marginBottom: "7px",
    width: "100%",
    borderRadius: "5px",
    display: "block",
    textTransform: "none",
    fontSize: "1.125rem",
    fontWeight: 400,
    borderWidth: "3px",
    border: "solid"
}));

export default OperationButtonOutlined