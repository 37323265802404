import { Box, Checkbox, Typography, styled } from "@mui/material"
import theme from "../../../config/Theme";
import {ReactComponent as FaceModality} from "../../../assets/modalityImages/faceModality.svg"
import {ReactComponent as VoiceModality} from "../../../assets/modalityImages/voiceModality.svg"
import { THEME } from "../../../config";
import { makeStyles } from "@mui/styles";

const StyledBox = styled(Box)(({ theme }) => ({
    width: "80%",
    border: "solid",
    borderRadius: "6px",
    borderWidth: "1px",
    borderColor: "#d7d7d7",
    transition: "200ms",
    ":hover": {
        borderColor: THEME.palette.primary.main
    },
}))

const useStyles = makeStyles(() => ({
    checked: {
        ":hover": {
            borderColor: THEME.palette.primary.main
        },
        borderColor: THEME.palette.primary.main
    },
    unChecked: {
        ":hover": {
            borderColor: THEME.palette.primary.main
        },
        borderColor: "#d7d7d7",
    },
    disabled: {
        "&:hover": {
            borderColor: "#d7d7d7"
        },
        backgroundColor: "#d7d7d7",
    },
    image: {
        width: "50%",
        borderRadius: "5px",
        display: "block",
        margin: "auto",
        marginBottom: "1rem",
        [theme.breakpoints.down("md")]: {
            width: "80%",
            height: "auto"
        }
    }
}))

interface ICheckboxBox {
    checked: boolean,
    setChecked: (check: boolean) => void,
    disabled?: boolean,
    type: "face" | "voice"
}

const CheckboxBox: React.FC<ICheckboxBox> = (props) => {

    const classes = useStyles()

    const getModalityImage = () => {
        switch(props.type) {
            case "face":
                return <FaceModality className={classes.image}/>;
            case "voice":
                return <VoiceModality className={classes.image}/>;
        }
    }

    const getName = () => {
        switch(props.type) {
            case "face":
                return "Face";
            case "voice":
                return "Voice"
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.disabled) return
        props.setChecked(event.target.checked)
    }

    const handleBoxClick = () => {
        if (props.disabled) return
        props.setChecked(!props.checked)
    }

    const getBoxStyle = () => {
        if (props.disabled) {
            return classes.disabled
        }
        if (props.checked) {
            return classes.checked
        } else {
            return classes.unChecked
        }
    }

    return (
        <StyledBox className={getBoxStyle()} onClick={handleBoxClick}>
            <Checkbox
                disabled={props.disabled}
                checked={props.checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            {getModalityImage()}
            <Typography align="center" style={{paddingBottom: "1rem"}}>
                {getName()}
            </Typography>
        </StyledBox>
    )
}

export default CheckboxBox