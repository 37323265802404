import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { FVInitParams } from 'neurotec-megamatcherid-management-client';
import React, { useEffect, useReducer, useState } from 'react';
import Navigation from './components/Navigation/Navigation';
import { THEME } from './config';
import { mmidInitParamsStorage, mmidPrefixStorage } from './config/const';
import Enroll from './containers/Enroll/Enroll';
import Info from './containers/Info/Info';
import { initialFvParameters, useLocalStorage } from './helpers/useLocalStorage';
import { AppContext } from './store/context';
import { appReducer, setDefaultOptions, setOptions, setPrefix } from './store/reducer';
import { initAppState } from './store/state';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import history from './config/history';
import Home from './containers/Home/Home';
import Settings from './containers/Settings/Settings';
import Checks from './containers/Checks/Checks';
import { ManagementAPI } from './config/management-api';
import Verify from './containers/Verify/Verify';
import VerifyFromQrCode from './containers/Verify/VerifyFromQrCode';
import SnackbarStyled from './components/Misc/Snackbar/SnackbarStyled';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { navigationReducer } from './components/Navigation/store/reducer';
import { initNavigationState } from './components/Navigation/store/state';
import { NavigationContext } from './components/Navigation/store/context';
import PageNotFound from './components/Misc/PageNotFound/PageNotFound';
import { generateSubjectPrefix } from './helpers/prefixGenerator';

const App: React.FC = () => {

  const { enqueueSnackbar } = useSnackbar();
  const options = useLocalStorage<FVInitParams>(mmidInitParamsStorage, initialFvParameters)[0];
  const [state, dispatch] = useReducer(appReducer, { ...initAppState, options });
  const [navigationState, navigationDispatch] = useReducer(navigationReducer, initNavigationState);
  const [allowSettingsOverride, setAllowSettingsOverride] = useState<boolean>(false)
  const [prefix] = useLocalStorage(mmidPrefixStorage, generateSubjectPrefix())

    useEffect(() => {
      const fetchData = async () => {
          ManagementAPI.getManagementInfo().then((res) => {
              const info = res.data;

              setAllowSettingsOverride(info.allowSettingsOverride as unknown as boolean)
              if (info.allowSettingsOverride) {
                  ManagementAPI.getDefaultProperties().then((res) => {
                      const properties = res.data;
                      dispatch(setDefaultOptions(properties))
                  }).catch((error) => {
                    enqueueSnackbar("Could not get default values", {variant: "error"})
                    dispatch(setOptions(info))
                  })
              } else {
                dispatch(setOptions(info))
              }

              if (info.prefixMode) {
                dispatch(setPrefix(prefix));
              } else {
                dispatch(setPrefix(null));
              }
          }).catch((error) => {
            dispatch(setPrefix(null));
            enqueueSnackbar("Could not connect to management", {variant: "error"})
            setTimeout(() => { enqueueSnackbar("Could not connect to management", {variant: "error"})}, 1000)
          })
      }
      fetchData();
    }, [enqueueSnackbar, prefix])

  return (
    
    <ThemeProvider theme={THEME}>
      <SnackbarProvider 
        maxSnack={3}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        preventDuplicate
        Components={{
          success: SnackbarStyled,
          error: SnackbarStyled,
          warning: SnackbarStyled,
          info: SnackbarStyled,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column"}}>
          <AppContext.Provider value={{ state, dispatch }}>
            <NavigationContext.Provider value={{ state: navigationState, dispatch: navigationDispatch }}>
              <Router history={history}>
                <Navigation settingsOverride={allowSettingsOverride} />
                <Switch>
                  <Route exact path="/" render={() => <Home />} />
                  <Route exact path="/settings" render={() => allowSettingsOverride? <Settings /> : <PageNotFound />} />
                  <Route exact path="/enroll" render={() => <Enroll />} />
                  <Route exact path="/verify" render={() => <Verify />} />
                  <Route exact path="/verifyfromqrcode" render={() => <VerifyFromQrCode />} />
                  <Route exact path="/check" render={() => <Checks />} />
                  <Route exact path="/info" render={() => <Info />} />
                  <Route exact path="*" render={() => <PageNotFound />} />
                  <Route>
                    <Redirect to="/404" />
                  </Route>
                </Switch>
              </Router>
            </NavigationContext.Provider>
          </AppContext.Provider>
        </Box >
      </SnackbarProvider>
    </ThemeProvider >
  );
}

export default App;
