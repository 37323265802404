import { Button, styled } from "@mui/material";

const OperationButtonContained = styled(Button)(({ theme }) => ({
    marginTop: "7px",
    marginBottom: "7px",
    width: "100%",
    borderRadius: "5px",
    display: "block",
    textTransform: "none",
    fontSize: "1.125rem",
    fontWeight: 400,
    backgroundImage: "linear-gradient(90deg, #5da3a5, #518382)"
}));

export default OperationButtonContained