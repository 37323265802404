import { makeStyles } from "@mui/styles";
import { Grid, IconButton, Input, TableCell, Tooltip, Typography, useTheme } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CachedIcon from '@mui/icons-material/Cached';
import { THEME } from "../../../config";
import { FVInitParams } from "neurotec-megamatcherid-management-client";

const useStyles = makeStyles(() => ({
    title: {
        fontWeight: 500,
        fontSize: "1.1rem",
    },
    titleDisabled: {
        opacity: "50%",
        fontWeight: 500,
        fontSize: "1.1rem",
    },
    iconButton: {
        opacity: "100%"
    },
    iconButtonDisabled: {
        opacity: "50%"
    },
    iconInfo: {
        marginLeft: "10px",
        position: "relative",
        top: "5px",
        color: THEME.palette.primary.main
    },
    tableCell: {
        paddingRight: 0,
        paddingLeft: 0,
        [useTheme().breakpoints.down("sm")]: {
            paddingLeft: "1rem"
        }
    },
    gridSlider: {
        paddingRight: "5%"
    },
    slider: {
        markLabel: {
            color: "red"
        }
    },
    typograhyName: {
        opacity: "100%",
        fontSize: "0.8rem",
        color: "gray"
    },
    typographyNameDisabled: {
        opacity: "50%",
        fontSize: "0.8rem",
        color: "gray"
    }
}))

const maximumValue = 5000;

interface IDoubleInputCell {
    title: string
    info?: string
    active: boolean
    colSpan: number
    qualityImageSize: [number, number]
    setQualityImageSize: React.Dispatch<React.SetStateAction<[number, number]>>
    options: FVInitParams
    setOptions: React.Dispatch<React.SetStateAction<FVInitParams>>
    saveSetting: (w: number, h: number) => void
}

const DoubleInputCell: React.FC<IDoubleInputCell> = (props) => {
    
    const classes = useStyles();

    const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let number = event.target.value === '' ? 0 : Number(event.target.value)
        if (number > maximumValue) {
            props.setQualityImageSize([maximumValue, props.qualityImageSize[1]])
            props.saveSetting(maximumValue, props.qualityImageSize[1])
            return
        }
        props.setQualityImageSize([number, props.qualityImageSize[1]])
        props.saveSetting(number, props.qualityImageSize[1])
    }

    const handleHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let number = event.target.value === '' ? 0 : Number(event.target.value)
        if (number > maximumValue) {
            props.setQualityImageSize([maximumValue, props.qualityImageSize[0]])
            props.saveSetting(maximumValue, props.qualityImageSize[0])
            return
        }
        props.setQualityImageSize([props.qualityImageSize[0], number])
        props.saveSetting(props.qualityImageSize[0], number)
    }

    const handleIconClick = () => {
        props.setQualityImageSize([1280, 720])
        props.saveSetting(1280, 720)
    }

    return (
        <TableCell colSpan={props.colSpan} className={classes.tableCell}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography component={'span'} className={props.active? classes.title : classes.titleDisabled}>
                        {props.title}
                        {props.info? 
                            <Tooltip disableFocusListener title={props.info} >
                                <div style={{display: "inline"}} >
                                    <HelpOutlineIcon className={classes.iconInfo} />
                                </div>
                            </Tooltip>
                        : null}
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid item xs={4} className={classes.gridSlider}>
                        <Typography className={props.active? classes.typograhyName : classes.typographyNameDisabled}>
                            Width
                        </Typography>
                        <Input
                            type="number"
                            disabled={!props.active}
                            value={Number(props.qualityImageSize[0])}
                            onChange={handleWidthChange}
                            inputProps={{
                                step: 10,
                                min: 0,
                                max: maximumValue,
                                type: 'number',
                            }}
                            color="primary"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={props.active? classes.typograhyName : classes.typographyNameDisabled}>
                            Height
                        </Typography>
                        <Input
                            type="number"
                            disabled={!props.active}
                            value={Number(props.qualityImageSize[1])}
                            onChange={handleHeightChange}
                            inputProps={{
                                step: 10,
                                min: 0,
                                max: maximumValue,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                            color="primary"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className={props.active? classes.typograhyName : classes.typographyNameDisabled}>
                            Total:
                        </Typography>
                        <Typography className={props.active? classes.typograhyName : classes.typographyNameDisabled}>
                            {props.qualityImageSize[0] * props.qualityImageSize[1]} px
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton onClick={handleIconClick} color="primary" className={props.active? classes.iconButton : classes.iconButtonDisabled}>
                            <CachedIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </TableCell>
    );
}

export default DoubleInputCell