import { makeStyles } from "@mui/styles";
import { TableCell, Typography, useTheme } from "@mui/material";

const useStyles = makeStyles(() => ({
    titleHead: {
        fontWeight: 500,
        fontSize: "1.25rem"
    },
    subtitleHead: {
        fontWeight: 300,
        fontSize: "1rem",
        color: "gray"
    },
    title: {
        paddingLeft: "2rem",
        fontWeight: 500,
        fontSize: "1rem",
        [useTheme().breakpoints.down("sm")]: {
            paddingLeft: "0"
        }
    },
    subtitle: {
        paddingLeft: "2rem",
        fontWeight: 300,
        fontSize: "1rem",
        color: "gray",
        [useTheme().breakpoints.down("sm")]: {
            paddingLeft: "0"
        }
    },
    tableCell: {
        width: "50%",
    }
}))

interface ITextCell {
    head?: boolean
    title: string
    subtitle: string
    subtitleSecond?: string
    colspan?: number
    width?: string
}

const TextCell: React.FC<ITextCell> = (props) => {

    const classes = useStyles();

    return(
        <TableCell className={classes.tableCell} colSpan={props.colspan? props.colspan : 1} width={props.width? props.width : "50%"}>
            <Typography className={props.head? classes.titleHead : classes.title}>
                {props.title}
            </Typography>
            <Typography className={props.head? classes.subtitleHead : classes.subtitle} >
                {props.subtitle}
            </Typography>
            {
                props.subtitleSecond ? 
                <Typography className={props.head? classes.subtitleHead : classes.subtitle} >
                    {props.subtitleSecond}
                </Typography> : null
            }
        </TableCell>
    );
}

export default TextCell;