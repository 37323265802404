import { Box, Typography } from '@mui/material'
import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react'

interface IQrCodeImage {
    base64Image: string | undefined
}

const QrCodeImage: React.FC<IQrCodeImage> = (props) => {

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const divRef = useRef<HTMLElement>(null);

    const clearAndDraw = () => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");
            ctx?.clearRect(0, 0, canvas.width, canvas.height);
            draw();
        }
    }

    const draw = useCallback(() => {
        if (canvasRef.current && divRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");
            if (ctx && props.base64Image) {
                const image = new Image();
                image.src = "data:image/png;base64, " + props.base64Image;
                image.onload = () => {
                    canvas.width = image.width;
                    canvas.height = image.height;
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                }
            }
        }
    }, [props]);

    useEffect(() => {
        clearAndDraw();
    });

    useLayoutEffect(() => {
        draw();
        window.addEventListener('resize', draw);
        return () =>
            window.removeEventListener('resize', draw);
    }, [draw])

    return (
        <Box ref={divRef} sx={{ display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", width: "100%" }}>
            {props.base64Image ?
                <canvas ref={canvasRef} style={{ minHeight: "30vh", minWidth: "30vh", maxWidth: "100%", maxHeight: "100%", imageRendering: "pixelated" }} />
                :
                <Typography>No image</Typography>
            }
        </Box>
    )
}

export default QrCodeImage
