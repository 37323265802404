import { Box } from "@mui/material";
import { BiometricVoiceData, Operation } from "neurotec-megamatcherid-management-client"
import TitleBar from "./TitleBar";
import { useRef } from "react";
import AudioOperationSummary from "../../OperationSummary/AudioOperationSummary";

interface IVoiceOverviewProps {
    audioInfo: BiometricVoiceData | undefined,
    operationInfo: Operation | undefined,
    bigger: boolean
}

const VoiceOverview: React.FC<IVoiceOverviewProps> = (props) => {

    const boxRef = useRef<HTMLDivElement>(null);

    return (
        <Box style={{marginLeft: "0.5rem"}}>
            <TitleBar beginText="Voice"/>
            <Box ref={boxRef} style={{marginTop: "1rem", marginBottom: "1rem", height: props.bigger? "60vh" : "30vh"}}>
                <AudioOperationSummary
                    audioInfo={props.audioInfo}
                    bigger={props.bigger}
                />
            </Box>
        </Box>
    )
}

export default VoiceOverview