import { Box, styled } from "@mui/material"
import SingleStatusPoint from "./components/SingleStatusPoint";
import { ModalityType } from "../../../containers/Enroll/Enroll";
import { AppContext } from "../../../store/context";
import React, { useContext } from 'react'

const ContainerBox = styled(Box)(() => ({
    minHeight: "inherit",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}));

interface IOperationModalityStatusProps {
    active: boolean
}

const OperationModalityStatus: React.FC<IOperationModalityStatusProps> = (props) => {

    const appState = useContext(AppContext).state

    return (
        props.active? 
            <ContainerBox>
                {appState.operationState.modalityState.map((s, i) => (
                    <SingleStatusPoint
                        key={i}
                        number={i + 1}
                        modalityState={s}
                        first={i === 0}
                        last={s.modality === ModalityType.OVERVIEW}
                        barsInvisible={s.modality === ModalityType.QR_SCAN && appState.operationState.modalityState.length === 1}
                    />)
                )}
            </ContainerBox> : null
        
    )
}

export default OperationModalityStatus