import { Configuration, ConfigurationParameters, JanusApi, ManagementApi, SubjectsApi, ImagesApi, BiometricDataApi, OperationsApi } from "neurotec-megamatcherid-management-client";

export const apiConfig: ConfigurationParameters = {
    basePath: "/api",
    username: "user",
    password: "admin"
}

export const JanusAPI = new JanusApi(new Configuration(apiConfig));
export const ManagementAPI = new ManagementApi(new Configuration(apiConfig));
export const SubjectsAPI = new SubjectsApi(new Configuration(apiConfig));
export const ImagesAPI = new ImagesApi(new Configuration(apiConfig));
export const BiometricDataAPI = new BiometricDataApi(new Configuration(apiConfig));
export const OperationsAPI = new OperationsApi(new Configuration(apiConfig))
