import { makeStyles } from "@mui/styles";
import { Dialog, DialogTitle, Grid, IconButton, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useState } from "react";
import { ILivenessActionSequence } from "../../../types/FaceVerification";
import { FVInitParams } from "neurotec-megamatcherid-management-client";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DialogButton from "../../../components/Misc/Dialog/Components/DialogButton";

const useStyles = makeStyles(() => ({
    dialog: {
    },
    dialogTitle: {
        fontWeight: 500,
        backgroundColor: "#f8f8f8"
    },
    titleTypography: {
        fontWeight: 500,
        fontSize: "1.2rem"
    },
    tableCell: {
        width: "50%",
    },
    selectGrid: {
        width: "100%",
    },
    closeButton: {
        color: "#000000"
    },
    select: {
        width: "100%",
        marginLeft: "1rem",
        paddingRight: "1rem",
    },
    buttonGrid: {
        margin: "1rem",
        textAlign: "center",
        marginTop: "2rem"
    },
    actionTypography: {
        fontWeight: 500,
        paddingLeft: "1rem"
    }
}))

interface ILivenessActionSequenceDialog {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    options: FVInitParams,
    apply: (string: string) => void,
    sequenceTokenize: () => ILivenessActionSequence[]
}

const LivenessActionSequenceDialog: React.FC<ILivenessActionSequenceDialog> = (props) => {

    const classes = useStyles();
    
    const [actionList, setActionList] = useState<ILivenessActionSequence[]>(props.sequenceTokenize())

    const stringifySequence = () => {
        let str: string = ""
        actionList.forEach((a) => {
            if (str === "")
                str = (a as string)
            else    
                str = str + "," + (a as string)
        })
        return str
    }

    const handleClose = () => {
        props.setOpen(false)
    }

    const handleSelectChange = (index: number, event: SelectChangeEvent<ILivenessActionSequence> ) => {
        setActionList([...actionList.slice(0, index), event.target.value as ILivenessActionSequence, ...actionList.slice(index + 1)])
    }

    const handleApply = () => {
        props.apply(stringifySequence())
        handleClose()
    }

    const handleNewAction = () => {
        setActionList([...actionList, (Object.keys(ILivenessActionSequence)[Object.values(ILivenessActionSequence).indexOf(ILivenessActionSequence.blink)] as ILivenessActionSequence)])
    }

    const handleDelete = (index: number) => {
        setActionList([...actionList.slice(0, index), ...actionList.slice(index + 1)])
    }

    const showSelect = (action: ILivenessActionSequence, index: number) => {
        return (
            <Grid container key={index}>
                <Grid item xs={12}>
                    <Typography className={classes.actionTypography}>
                        Action {index + 1}
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid item xs={10} className={classes.selectGrid}>
                        <Select
                            color="primary"
                            value={action}
                            onChange={(event) => {handleSelectChange(index, event)}}
                            className={classes.select}
                        >
                        {Object.keys(ILivenessActionSequence).map((a, i) => (
                            <MenuItem key={i} value={a}>{(ILivenessActionSequence as any)[a]}</MenuItem>
                        ))}
                        </Select>
                    </Grid>
                    <Grid item xs={2} style={{textAlign: "center"}}>
                        <IconButton color="primary" onClick={() => {handleDelete(index)}}>
                            <DeleteForeverOutlinedIcon fontSize="large" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return(
        <Dialog onClose={handleClose} open={props.open} className={classes.dialog} fullWidth maxWidth="xs">
        <DialogTitle className={classes.dialogTitle}>
            <Grid container>
                <Grid item xs={10}>
                    <Typography component={"span"} textAlign={"left"} className={classes.titleTypography}>
                        Liveness sequence check
                    </Typography>
                </Grid>
                <Grid item xs={2} style={{textAlign: "right"}}>
                    <IconButton color="primary" onClick={handleClose} className={classes.closeButton}>
                        <CloseIcon fontSize="medium" />
                    </IconButton>
                </Grid>
            </Grid>
        </DialogTitle>
        <Grid container>
            {actionList.map((a, i) => (
                showSelect(a, i)
            ))}
            <Grid container className={classes.buttonGrid}>
                <Grid item xs={6}>
                    <DialogButton variant="outlined" color="secondary" onClick={handleNewAction}>
                        + Add new Action
                    </DialogButton>
                </Grid>
                <Grid item xs={6}>
                    <DialogButton variant="contained" color="primary" onClick={handleApply}>
                        Apply
                    </DialogButton>
                </Grid>
            </Grid>
        </Grid>
      </Dialog>
    );
}

export default LivenessActionSequenceDialog;