import { Skeleton, Theme, styled, useMediaQuery } from "@mui/material";
import theme from "../../config/Theme";
import { useRef, useState } from "react";

const GuidelineImg = styled('img')({
    width: "100%",
    height: "15rem",
    borderRadius: "5px",
    display: "block",
    margin: "auto",
    marginBottom: "1rem",
    backgroundColor: "red",
    [theme.breakpoints.down("md")]: {
        width: "80%",
        height: "auto"
    }
}); 

const StyledSkeleton = styled(Skeleton)({
    width: "100%",
    height: "15rem",
    borderRadius: "5px",
    display: "block",
    margin: "auto",
    marginBottom: "1rem",
    [theme.breakpoints.down("md")]: {
        width: "80%",
        height: "auto"
    }
})

interface IGuidelineImageProps {
    src: string
}

const GuidelineImage: React.FC<IGuidelineImageProps> = (props) => {

    const skltRef = useRef<HTMLSpanElement>(null);
    const [loaded, setLoaded] = useState(false)
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const getHeight = (): string => {
        if (isMobile) {
            if (skltRef.current) {
                return (skltRef.current.clientWidth * 2 / 3) + "px"
            } else {
                return "auto"
            }
        }
        return "15rem"
    }

    return (
        <>
            {loaded ? null : <StyledSkeleton variant="rectangular" ref={skltRef} height={getHeight()}/>}
            <GuidelineImg
                style={{display: loaded ? "block" : "none"}}
                src={props.src} onLoad={() => setLoaded(true)}
                alt=""
            />
        </>
    )
}

export default GuidelineImage