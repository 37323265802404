import { IconButton } from "@mui/material"
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useEffect, useState } from "react";

interface IGoBack {
    visible?: boolean
}

const GoBack: React.FC<IGoBack> = () => {

    const history = useHistory();
    const [visible, setVisible] = useState(true)
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname === "/") {
            setVisible(false);
        } else {
            setVisible(true)
        }
    }, [pathname])

    const handleIconButton = () => {
        history.push("/");
    }

    return (
        <>
            {visible ?
                <IconButton style={{alignSelf: "stretch"}} onClick={handleIconButton}>
                    <ArrowBackIosNewIcon />
                </IconButton>
            :null}
        </>

    )
}

export default GoBack