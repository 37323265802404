import { makeStyles } from "@mui/styles";
import { Button, Dialog, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SubjectsAPI } from "../../../config/management-api";
import QrCodeImage from "../QrCode/QrCodeImage";
import { THEME } from "../../../config";
import SubjectIdAutocomplete from "./SubjectIdAutocomplete";
import { useSnackbar } from "notistack";
import { getManagementInfo } from "../../../helpers/startOperation";
import { useHistory } from "react-router-dom";
import CheckboxBoxContainer from "../../Enroll/components/CheckboxBoxContainer";
import { IModality, IOperationState, ModalityStateStatus } from "../../../store/actions";
import { ModalityType } from "../../Enroll/Enroll";
import { AppContext } from "../../../store/context";
import { setActiveModalities, setOperationState } from "../../../store/reducer";

const useStyles = makeStyles(() => ({
    dialog: {

    },
    title: {
        fontSize: "1.25rem",
        backgroundColor: THEME.palette.background.default
    },
    text: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
    },
    gridIcon: {
        textAlign: "right",
        fontSize: "1.2rem",
        color: THEME.palette.primary.main
    },
    gridContent: {
        paddingTop: "1rem"
    },
    gridText: {
        alignSelf: "center",
    },
    gridAutoComplete: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingTop: "1rem"
    },
    gridRadio: {
        paddingLeft: "2rem"
    },
    buttonCancel: {
        fontSize: "1.125rem",
        width: "95%",
        textTransform: "none"
    },
    buttonConfirm: {
        fontSize: "1.125rem",
        width: "95%",
        textTransform: "none"
    },
    gridButton: {
        margin: "1rem",
        textAlign: "center",
        marginTop: "2rem",
    },
    radio: {
        color: THEME.palette.primary.main,
    }
}))

enum VerifyOptions {
    ENROLLED = "Enrolled",
    SHOW_QR = "Show QR",
    WITH_QR = "With QR"
}

interface IVerifyDialog {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const VerifyDialog: React.FC<IVerifyDialog> = (props) => {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [option, setOption] = useState<VerifyOptions>(VerifyOptions.ENROLLED)
    const [qrCode, setQrCode] = useState<string>("")
    const [active, setActive] = useState<boolean>(true)
    const history = useHistory()
    const [selectedOptions, setSelectedOptions] = useState<IModality>({faceModality: false, voiceModality: false})
    const [disabledOptions, setDisabledOptions] = useState<IModality>({faceModality: false, voiceModality: false})
    const {state, dispatch} = useContext(AppContext);
    const [subjectId, setSubjectId] = useState<string>("");
    const [error, setError] = useState("");
    const [isSubjectIdGood, setIsSubjectIdGood] = useState(false)
    const [loadingNext, setLoadingNext] = useState(false)
    const [loadingShow, setLoadingShow] = useState(false)

    useEffect(() => {
        const fetchInfo = async () => {
            const managementInfo = await getManagementInfo();
            if (!managementInfo) {
                enqueueSnackbar("Could not connect to the server", {variant: "error"})
                setActive(false)
                return;
            } else {
                setActive(true)
            }
        }
        fetchInfo()
    }, [enqueueSnackbar])

    const getQrCode =  () => {
        setLoadingShow(true)
        SubjectsAPI.getSubjectsQRCodeBySubjectId({subjectid: state.prefix? state.prefix + "." + subjectId : subjectId}).then(res => {
            setLoadingShow(false)
            if (res.data.qrcode) {
                setQrCode(res.data.qrcode)
            } else {
                enqueueSnackbar("Qr code in undefined", {variant: "error"})
            }
        }).catch(error => {
            setLoadingShow(false)
            if (error.isAxiosError && error.response.status) {
                if (error.response.status === 404) {
                    enqueueSnackbar("404, subject not found", {variant: "error"})     
                    return;
                }
            }
            enqueueSnackbar(error.message, {variant: "error"})  
        })
    }

    const handleSubjectChange = (subject: string | null | undefined) => {
        if (subject) 
            setSubjectId(subject);
        else {
            setError("")
            setSubjectId("")
        }
    }

    const handleClose = () => {
        props.setOpen(false)
        setTimeout(() => {
            setOption(VerifyOptions.ENROLLED)
            setQrCode("")
            setIsSubjectIdGood(false)
            setError("")
            setSubjectId("")
            setSelectedOptions({faceModality: false, voiceModality: false})
        }, 300);
    }

    const handleCancel = () => {
        handleClose()
    }

    const handleStart = () => {
        let subjectIdRequest = ""
        if (state.prefix) {
            subjectIdRequest = state.prefix + "." + subjectId;
        } else {
            subjectIdRequest = subjectId
        }
        let operationStatus: IOperationState = {subjectId: subjectIdRequest, modalityState: []}
        if (selectedOptions.faceModality) {
            operationStatus.modalityState.push({modality: ModalityType.FACE_MODALITY, state: ModalityStateStatus.ACTIVE})
        }
        if (selectedOptions.voiceModality) {
            if (operationStatus.modalityState.length === 0) {
                operationStatus.modalityState.push({modality: ModalityType.VOICE_MODALITY, state: ModalityStateStatus.ACTIVE})

            } else {
                operationStatus.modalityState.push({modality: ModalityType.VOICE_MODALITY, state: ModalityStateStatus.TO_BE_DONE})
            }
        }
        operationStatus.modalityState.push({modality: ModalityType.OVERVIEW, state: ModalityStateStatus.TO_BE_DONE})
        dispatch(setOperationState(operationStatus))
        dispatch(setActiveModalities(selectedOptions))
        setTimeout(() => {
            history.push("/verify")
            handleClose()
        }, 50)
    }

    const handleNext = () => {
        if (!subjectId || /^\s*$/.test(subjectId)) {
            setError("SubjectID cannot be empty")
            return;
        }
        setLoadingNext(true)
        let subjectIdRequest = ""
        if (state.prefix) {
            subjectIdRequest = state.prefix + "." + subjectId;
        } else {
            subjectIdRequest = subjectId
        }
        SubjectsAPI.getSubjectsEnrolledModalities({subjectId: subjectIdRequest}).then((modalitiesResult) => {
            const data = modalitiesResult.data
            setDisabledOptions({faceModality: data.faceModality ?? false, voiceModality: data.voiceModality ?? false})
            setIsSubjectIdGood(true)
            setLoadingNext(false)
        }).catch((e: any) => {
            setError("Could not get modalities")
            console.error(e)
            setLoadingNext(false)
            return; 
        })
    }

    const handleConfirm = (route: "verify" | "verifyfromqrcode" | false) => {
        if (qrCode.length > 0) {
            setQrCode("")
        } else {
            switch (option) {
                case VerifyOptions.ENROLLED:
                    history.push(route as string)
                    break;
                case VerifyOptions.WITH_QR:
                    history.push(route as string)
                    break;
                case VerifyOptions.SHOW_QR:
                    handleShowQRCode()
                    break;
            }
        }
    }

    const getEnrolledConfirmButton = () => {
        if (!isSubjectIdGood) {
            return (
                <Button
                    className={classes.buttonConfirm}
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={(subjectId.length === 0 || error.length !== 0) || loadingNext}
                >
                    Next
                </Button>
            )
        } else {
            return (
                <Button className={classes.buttonConfirm} variant="contained" color="primary" onClick={handleStart} disabled={!active}>
                    Start
                </Button>
            )
        }
    }

    const handleStartQrScanningClick = () => {
        let operationStatus: IOperationState = {subjectId: "", modalityState: []}
        operationStatus.modalityState.push({modality: ModalityType.QR_SCAN, state: ModalityStateStatus.ACTIVE})
        dispatch(setOperationState(operationStatus))
        dispatch(setActiveModalities({qrScan: true, faceModality: false, voiceModality: false}))
        handleConfirm("verifyfromqrcode")
    }

    const handleConfirmButton = () => {
        switch (option) {
            case VerifyOptions.ENROLLED:
                return getEnrolledConfirmButton()
            case VerifyOptions.WITH_QR:
                return (
                    <Button className={classes.buttonConfirm} variant="contained" color="primary" onClick={handleStartQrScanningClick} disabled={!active}>
                        {getConfirmButtonText()}
                    </Button>
                )
            case VerifyOptions.SHOW_QR:
                return (
                    <Button className={classes.buttonConfirm} variant="contained" color="primary" onClick={() => handleConfirm(false)} disabled={!active || loadingShow}>
                        {getConfirmButtonText()}
                    </Button>
                )
        }
    }

    const handleShowQRCode = () => {
        getQrCode();
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setOption(value as VerifyOptions)
    }

    const getCancelButtonText = () => {
        return "Cancel"
    }

    const getConfirmButtonText = () => {
        if (qrCode.length > 0) {
            return "Return"
        } else {
            switch (option) {
                case VerifyOptions.ENROLLED:
                    return "Next"
                case VerifyOptions.WITH_QR:
                    return "Start"
                case VerifyOptions.SHOW_QR:
                    return "Show"
            }
        }
    }

    const getSubjectIdSelection = () => {
        return (
            <Grid item xs={12} className={classes.gridAutoComplete}>
                <SubjectIdAutocomplete
                    error={error}
                    selectedSubject={subjectId}
                    handleSubjectChange={handleSubjectChange}
                />
            </Grid>
        )
    }

    const getOptionSelector = () => {
        return (
            <>
                <Grid item xs={12} className={classes.gridText}>
                    <Typography className={classes.text}>
                        Choose option:
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.gridRadio}>
                    <RadioGroup
                        value={option}
                        color="primary"
                        onChange={handleChange}
                    >
                        <FormControlLabel value={VerifyOptions.ENROLLED} control={<Radio color="primary" className={classes.radio}/>} label="With enrolled person" />
                        <FormControlLabel value={VerifyOptions.WITH_QR} control={<Radio color="primary" className={classes.radio} />} label="With QR code" />
                        <FormControlLabel value={VerifyOptions.SHOW_QR} control={<Radio color="primary" className={classes.radio} />} label="Show QR code" />
                    </RadioGroup>
                </Grid>
                {option === VerifyOptions.SHOW_QR || option === VerifyOptions.ENROLLED? getSubjectIdSelection() : null}
            </>
        );
    }

    const getQrCodeDialogContent = () => {
        return (
            <QrCodeImage base64Image={qrCode} />
        );
    }

    const getCheckboxBoxContainer = () => {
        return (
            <CheckboxBoxContainer
                faceModality={selectedOptions.faceModality}
                voiceModality={selectedOptions.voiceModality}
                faceModalityDisabled={!disabledOptions.faceModality}
                voiceModalityDisabled={!disabledOptions.voiceModality}
                setFaceModality={(c) => setSelectedOptions({...selectedOptions, faceModality: c})}
                setVoiceModality={(c) => setSelectedOptions({...selectedOptions, voiceModality: c})}
            />
        )
    }

    const getDialogContent = () => {
        if (isSubjectIdGood) return getCheckboxBoxContainer()
        if (qrCode.length > 0) {
            return getQrCodeDialogContent()
        } 
        else {
            return getOptionSelector()
        }        
    }

    return (
        <Dialog onClose={handleClose} open={props.open} maxWidth="xs" className={classes.dialog}>
        <DialogTitle className={classes.title}>Verify</DialogTitle>
            <Grid container>
                <Grid container className={classes.gridContent}>
                    {getDialogContent()}
                </Grid>
                <Grid container className={classes.gridButton}>
                    <Grid item xs={6} >
                        <Button className={classes.buttonCancel} variant="outlined" color="secondary" onClick={handleCancel}>
                            {getCancelButtonText()}
                        </Button>
                    </Grid>
                    <Grid item xs={6} >
                        {handleConfirmButton()}
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
}

export default VerifyDialog