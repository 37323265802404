import { IOperationsStatus } from "../../../types/FaceVerification";
import { RefVideoStream } from "../../VideoStream/BiometricFaceStream";
import VideoGrid from "../../VideoStream/VideoGrid";
import React, { useContext, useEffect } from "react";
import { NavigationContext } from "../../../components/Navigation/store/context";
import { AppContext } from "../../../store/context";
import { setOptionsActive } from "../../../components/Navigation/store/reducer";
import { showMessage } from "../../../store/reducer";
import BiometricVoiceStream from "../../AudioStream/BiometricAudioStream";

interface IVoiceOperation {
    setCheckState: React.Dispatch<React.SetStateAction<IOperationsStatus>>
    childRef: React.RefObject<RefVideoStream>
    setOperationUUID: (uuid: string) => void
}

const VoiceOperation: React.FC<IVoiceOperation> = (props) => {

    const dispatch = useContext(AppContext).dispatch;
    const navigationDispatch = useContext(NavigationContext).dispatch

    useEffect(() => {
        let effectRef: RefVideoStream | undefined = undefined
        if (props.childRef.current)
            effectRef = props.childRef.current

        return () => {
            if (effectRef !== undefined && effectRef.cancel)
                effectRef.cancel()
            navigationDispatch(setOptionsActive(true))
            dispatch(showMessage({ message: "", type: "info" }))
        }
    }, [navigationDispatch, dispatch, props.childRef])
    
    return (
        <VideoGrid item md={10} xs={12} style={{paddingRight: "0px", paddingLeft: "0px"}}>
            <BiometricVoiceStream
                ref={props.childRef}
                setOperationStatus={props.setCheckState}
                setOperationUUID={props.setOperationUUID}
            />
        </VideoGrid>
    )
}

export default VoiceOperation;