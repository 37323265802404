import { FVInitParams } from "neurotec-megamatcherid-management-client";
import React, { SetStateAction, useEffect, useState } from "react"

export const initialFvParameters: FVInitParams = {
    livenessMode: 1,
    checkIcaoCompliance: false,
    timeout: 0,
    advancedParameters: [
        { key: "FramesToSkip", value: "100" },
        { key: "StrictQualityImageSize", value: "921600" },
        { key: "StrictQualityTimeout", value: "30000" }
    ]
}

export const useLocalStorage = <T extends Object>(key: string, defaultValue: T): [T, React.Dispatch<SetStateAction<T>>] => {
    const [value, setValue] = useState<T>(() => {
        const local = localStorage.getItem(key);
        return local !== null ? JSON.parse(local) as T : defaultValue;
    })

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value))
    }, [value, key]);

    return [value, setValue]
};