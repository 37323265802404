import React from 'react'
import Management from '../Settings/tabs/Management'
import About from '../Settings/tabs/About'
import { SettingContainer } from '../Settings/Settings'

const Info: React.FC = () => {

    return (
        <SettingContainer style={{paddingTop: "2rem"}}>
            <About />
            <Management />
        </SettingContainer> 
    )
}

export default Info
