import { FVAdvancedInitParams, FVInitParams, SessionData } from "neurotec-megamatcherid-management-client";
import { AddSession, AppActions, AppActionType, IMessage, AddQrSession, RemoveSession, SetOptions, ShowMessage, ShowOperationSummary, ShowQrCode, RemoveQrSession, SetDefaultOptions, IModality, SetActiveModalities, SetOperationState, IOperationState, SetChecksTitle, SetPrefix } from "./actions";
import { AppState } from "./state";

export const appReducer = (state: AppState, action: AppActions): AppState => {
    switch (action.type) {
        case AppActionType.AddSession:
            return { ...state, session: action.session, options: action.mmidInitParameters }
        case AppActionType.RemoveSession:
            return { ...state, session: null}
        case AppActionType.ShowMessage:
            if (state.message.message === action.message.message)
                return state;
            return { ...state, message: action.message }
        case AppActionType.SetOptions:
            return { ...state, options: action.options }
        case AppActionType.SetDefaultOptions:
            return { ...state, defaultOptions: action.defaultOptions }
        case AppActionType.ShowOperationSummary:
            return { ...state, showOperationSummary: action.operationId }
        case AppActionType.ShowQrCode:
            return { ...state, showQrCode: action.subjectId }
        case AppActionType.AddQrSession:
            return { ...state, qrSession: true }
        case AppActionType.RemoveQrSession:
            return { ...state, qrSession: false }
        case AppActionType.SetActiveModalities:
            return { ...state, activeModalities: action.activeModalities }
        case AppActionType.SetOperationState:
            return { ...state, operationState: action.operationState }
        case AppActionType.SetChecksTitle:
            return { ...state, checksTitle: action.checksTitle}
        case AppActionType.SetPrefix:
            return {...state, prefix: action.prefix}
        default:
            return state;
    }
}

export const addSession = (session: SessionData | null, mmidInitParameters: FVInitParams | null): AddSession => {
    return {
        type: AppActionType.AddSession,
        session,
        mmidInitParameters
    }
}

export const removeSession = (): RemoveSession => {
    return {
        type: AppActionType.RemoveSession,
    }
}

export const setOptions = (options: FVInitParams): SetOptions => {
    return {
        type: AppActionType.SetOptions,
        options
    }
}

export const setDefaultOptions = (defaultOptions: FVAdvancedInitParams[]): SetDefaultOptions => {
    return {
        type: AppActionType.SetDefaultOptions,
        defaultOptions
    }
}

export const showMessage = (message: IMessage): ShowMessage => {
    return {
        type: AppActionType.ShowMessage,
        message
    }
}

export const showOperationSummary = (operationId: string): ShowOperationSummary => {
    return {
        type: AppActionType.ShowOperationSummary,
        operationId
    }
}

export const showQrCode = (subjectId: string): ShowQrCode => {
    return {
        type: AppActionType.ShowQrCode,
        subjectId
    }
}

export const addQrSession = (): AddQrSession => {
    return {
        type: AppActionType.AddQrSession,
    }
}

export const removeQrSession = (): RemoveQrSession => {
    return {
        type: AppActionType.RemoveQrSession,
    }
}

export const setActiveModalities = (activeModalities: IModality): SetActiveModalities => {
    return {
        type: AppActionType.SetActiveModalities,
        activeModalities
    }
}

export const setOperationState = (operationState: IOperationState): SetOperationState => {
    return {
        type: AppActionType.SetOperationState,
        operationState
    }
}

export const setChecksTitle = (checksTitle: string): SetChecksTitle => {
    return {
        type: AppActionType.SetChecksTitle,
        checksTitle
    }
}

export const setPrefix = (prefix: string | null): SetPrefix => {
    return {
        type: AppActionType.SetPrefix,
        prefix
    }
}
