import { Grid, styled } from "@mui/material";
import Message from "../../components/Message/Message";

const MainVideoGrid = styled(Grid)(({ theme }) => ({
    height: "80vh",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingTop: "0.5rem",
    borderRadius: "5px",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    alignContent: "center",
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.down("md")]: {
        height: "80vh"
    }
}));

interface IVideoGrid {
    children?: JSX.Element,
    item?: boolean,
    md?: number,
    xs?: number,
    style?: any
}

const VideoGrid = ({children, item, md, xs}: IVideoGrid): JSX.Element => {

    return (
        <MainVideoGrid item={item} md={md} xs={xs}>
            {children}
            <Message />
        </MainVideoGrid>
    )
}

export default VideoGrid