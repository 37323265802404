import React, { createContext } from "react";
import { AppActions } from "./actions";
import { AppState, initAppState } from "./state";

export const AppContext = createContext<{
    state: AppState,
    dispatch: React.Dispatch<AppActions>
}>({
    state: initAppState,
    dispatch: () => undefined
});