import { makeStyles } from "@mui/styles";
import { Button, TableCell, Typography } from "@mui/material";
import { useState } from "react";
import LivenessActionSequenceDialog from "./LivenessActionSequenceDialog";
import { FVInitParams } from "neurotec-megamatcherid-management-client";
import { NormalTableRow, SmallContentTableRow, SmallTextTableRow } from "../Settings";
import TextCell from "./TextCell";
import { ILivenessActionSequence } from "../../../types/FaceVerification";

const useStyles = makeStyles(() => ({
    tableCell: {
        width: "50%",
    },
    button: {
        opacity: "100%",
        backgroundImage: "linear-gradient(90deg, #5da3a5, #518382)",
    },
    disabledButton: {
        opacity: "50%",
        backgroundImage: "linear-gradient(90deg, #5da3a5, #518382)",
    },
    typography: {
        color: "red"
    }
}))

interface ILivenessActionSequenceRow {
    isMobile: boolean,
    active: boolean,
    options: FVInitParams,
    apply: (string: string) => void
}

const LivenessActionSequenceRow: React.FC<ILivenessActionSequenceRow> = (props) => {

    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false)

    const handleButtonPress = () => {
        setOpen(true)
    }

    const sequenceTokenize = () => {
        let list: ILivenessActionSequence[] = []
        props.options.advancedParameters?.find((e) => e.key === "LivenessCustomActionSequence")?.value?.split(',').forEach((a) => {
            list.push(a as ILivenessActionSequence)
        })
        if (list.length === 0) {
            return [""] as any as ILivenessActionSequence[]
        }
        return list
    }

    const getSequence = () => {
        let string: string = ""
        props.options.advancedParameters?.find((e) => e.key === "LivenessCustomActionSequence")?.value?.split(',').forEach((a) => {
            if (string !== "") string += ", "
            string += (ILivenessActionSequence as any)[a]
        })
        if (string === "" || string === undefined || string === "undefined")
            return "none"
        return string
    }

    const getDialogCell = (span: number) => {
        return (
            <TableCell colSpan={span} className={classes.tableCell} align={"center"}>
                <Button className={props.active? classes.button : classes.disabledButton} color="primary" onClick={handleButtonPress} variant="contained" disabled={!props.active}>
                    Edit action sequence
                </Button>
                <LivenessActionSequenceDialog
                    sequenceTokenize={sequenceTokenize}
                    open={open}
                    setOpen={setOpen}
                    options={props.options}
                    apply={props.apply}
                />
            </TableCell>
        )
    }

    return (
        props.isMobile? 
            <>
            <SmallTextTableRow>
                <TextCell colspan={3} title="Liveness Custom Action Sequence" subtitle="Action sequence to be used in Custom liveness. If left empty, random actions are returned."/>
            </SmallTextTableRow>
            <SmallContentTableRow style={{borderBottom: "none"}}>
                {getDialogCell(3)}
            </SmallContentTableRow>
            <SmallContentTableRow>
                <TableCell colSpan={3} style={{paddingTop: "0px"}} >
                    <Typography style={{paddingLeft: "0px"}}>
                        <b>
                            Applied sequence:&nbsp;
                        </b>
                        {getSequence()}
                    </Typography>
                </TableCell>
            </SmallContentTableRow>
            </>
        :
            <>
            <NormalTableRow style={{borderBottom: "none"}}>
                <TextCell colspan={2} title="Liveness Custom Action Sequence" subtitle="Action sequence to be used in Custom liveness. If left empty, random actions are returned."/>
                {getDialogCell(2)}
            </NormalTableRow>
            <NormalTableRow>
                <TableCell colSpan={4} style={{paddingTop: "0px"}} >
                    <Typography style={{paddingLeft: "2rem"}}>
                        <b>
                            Applied sequence:&nbsp;
                        </b>
                        {getSequence()}
                    </Typography>
                </TableCell>
            </NormalTableRow>
            </>

    )
}

export default LivenessActionSequenceRow;