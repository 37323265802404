import { Button, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import TextCell from "../components/TextCell";
import ConfirmationDialog from "../../../components/Misc/Dialog/ConfirmationDialog";
import { useState } from "react";
import { SettingTable, settingUseStyles } from "../Settings";
import { useSnackbar } from "notistack";

interface IReset {
    reset: () => void
}

const Reset: React.FC<IReset> = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false)
    const classes = settingUseStyles()

    const handleButtonClick = () => {
        setOpen(true)
    }

    const resetToDefault = () => {
        props.reset();
        enqueueSnackbar("Settings reset", {variant: "info"})
    }

    return (
        <>
            <TableContainer component={Paper} className={classes.container}>
                <SettingTable>
                    <TableHead>
                        <TableRow>
                            <TextCell colspan={2} head title="Reset settings" subtitle="You may restore the default values for all setting groups here."/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TextCell title="" subtitle="Restore settings to their original default values" />
                            <TableCell align="right">
                                <Button onClick={handleButtonClick} variant="contained" color="error">
                                    Reset Settings
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </SettingTable>
            </TableContainer>
            <ConfirmationDialog
                open={open}
                setOpen={setOpen}
                confirmColor="error"
                title="Reset settings"
                text="All settings will be set to their default values"
                cancelText="Cancel"
                confirmText="Yes, reset everything"
                confirm={resetToDefault}
            />
        </>
    );
}

export default Reset