import { makeStyles } from "@mui/styles";
import { Switch, TableCell, useTheme } from "@mui/material"

const useStyles = makeStyles(() => ({
    switch: {
        marginRight: "2rem",
        [useTheme().breakpoints.down("sm")]: {
            marginRight: "0px"
        }
    },
    cell: {
        textAlign: "right",
        [useTheme().breakpoints.down("sm")]: {
            paddingRight: "5px"
        }
    }
}))

interface ISwitchCell {
    state: boolean
    setState: (bool: boolean) => void
    colSpan?: number
    disabled?: boolean
}

const SwitchCell: React.FC<ISwitchCell> = (props) => {
    const classes = useStyles();
    const label = { inputProps: { 'aria-label': 'Color switch demo' } };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setState(event.target.checked)
    }

    return(
        <TableCell className={classes.cell} colSpan={props.colSpan? props.colSpan : 1}>
            <Switch disabled={props.disabled} {...label} className={classes.switch} color="primary" checked={props.state} onChange={handleChange} />
        </TableCell>
    )
}

export default SwitchCell
