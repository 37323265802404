import { Avatar, Box, Theme, Typography, styled, useMediaQuery } from "@mui/material";
import { IModalityState, ModalityStateStatus } from "../../../../store/actions"
import { ModalityType } from "../../../../containers/Enroll/Enroll";
import { THEME } from "../../../../config";

const ContainerBox = styled(Box)(() => ({
    height: "100%",
    alignItems: "center"
}));

const LineBox = styled(Box)(() => ({
    color: "red",
    height: "2px",
    width: "55%",
    backgroundColor: "#ff0000",
}));

const Bubble = styled(Avatar)(({theme}) => ({
    borderWidth: "2px",
    width: "auto",
    aspectRatio: "1/1",
    fontSize: "0.875rem",
    height: "80%",
}))

interface ISingleStatusPoint {
    number: number,
    modalityState: IModalityState,
    first?: boolean,
    last?: boolean,
    barsInvisible?: boolean
}

const SingleStatusPoint: React.FC<ISingleStatusPoint> = (props) => {

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const getModalityName = (): string => {
        switch (props.modalityState?.modality) {
            case ModalityType.QR_SCAN:
                return "Qr scan"
            case ModalityType.FACE_MODALITY:
                return "Face";
            case ModalityType.VOICE_MODALITY:
                return "Voice";
            case ModalityType.OVERVIEW:
                return "Overview";
        }
    }

    const getBackgroundColor = (): string => {
        switch (props.modalityState.state) {
            case ModalityStateStatus.ACTIVE:
                return "#fefefe"
            case ModalityStateStatus.DONE:
                return THEME.palette.primary.main
            case ModalityStateStatus.TO_BE_DONE:
                return "#c9c9c9"
        }
    }

    const getBorderColor = (): string => {
        switch (props.modalityState.state) {
            case ModalityStateStatus.ACTIVE:
                return THEME.palette.primary.main
            case ModalityStateStatus.DONE:
                return THEME.palette.primary.main
            case ModalityStateStatus.TO_BE_DONE:
                return "#c9c9c9"
        }
    }

    const getTextColor = (): string => {
        switch (props.modalityState.state) {
            case ModalityStateStatus.ACTIVE:
                return THEME.palette.text.primary
            case ModalityStateStatus.DONE:
                return THEME.palette.text.secondary
            case ModalityStateStatus.TO_BE_DONE:
                return THEME.palette.text.primary
        }
    }

    const getLineColor = (): string => {
        switch (props.modalityState.state) {
            case ModalityStateStatus.ACTIVE:
            case ModalityStateStatus.DONE:
                return THEME.palette.primary.main
            case ModalityStateStatus.TO_BE_DONE:
                return "#c9c9c9"
        }
    }

    return (
        <ContainerBox style={{ alignContent: "center", alignItems: "center"}}>
            <Box style={{position: "relative", paddingLeft: isMobile? "1rem" : "2rem", paddingRight: isMobile? "1rem" : "2rem", marginTop: "0.5rem", height: "50%"}}>
                <div style={{display: "flex", position: "absolute", transform: "translateY(-50%)", width: "105%", top: "48%", left: "0"}}>
                    <LineBox visibility={props.first || props.barsInvisible ? "hidden" : "visible"} style={{backgroundColor: getLineColor()}}/>
                    <LineBox visibility={props.last || props.barsInvisible ? "hidden" : "visible"} style={{backgroundColor: getLineColor()}}/>
                </div>
                <Bubble sx={{
                    color: getTextColor(),
                    backgroundColor: getBackgroundColor(),
                    border: "solid " + getBorderColor(),
                }}>
                    {props.number}
                </Bubble>
            </Box>
            <Typography align="center" fontSize={"0.875rem"}>{getModalityName()}</Typography>
        </ContainerBox>
    )
}

export default SingleStatusPoint