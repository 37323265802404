import { FVAdvancedInitParams, FVInitParams, SessionData } from "neurotec-megamatcherid-management-client";
import { ModalityType } from "../containers/Enroll/Enroll";


export enum AppActionType {
    AddSession,
    RemoveSession,
    ShowMessage,
    ShowOperationSummary,
    ShowQrCode,
    AddQrSession,
    RemoveQrSession,
    SetOptions,
    SetDefaultOptions,
    SetActiveModalities,
    SetOperationState,
    SetChecksTitle,
    SetPrefix,
}

export interface IMessage {
    message: string,
    type: "info" | "error" | "success" | "warning" | "failure";
}
export interface IModality {
    qrScan?: boolean,
    faceModality: boolean,
    voiceModality: boolean,
}

export interface IOperationState {
    subjectId: string,
    modalityState: IModalityState[]
}

export interface IModalityState {
    modality: ModalityType
    state: ModalityStateStatus
}

export enum ModalityStateStatus {
    ACTIVE = "ACTIVE",
    DONE = "DONE",
    TO_BE_DONE = "TO_BE_DONE"
}

export interface AddSession {
    type: AppActionType.AddSession,
    session: SessionData | null,
    mmidInitParameters: FVInitParams | null
}

export interface RemoveSession {
    type: AppActionType.RemoveSession
}

export interface ShowMessage {
    type: AppActionType.ShowMessage,
    message: IMessage
}

export interface ShowOperationSummary {
    type: AppActionType.ShowOperationSummary,
    operationId: string
}

export interface ShowQrCode {
    type: AppActionType.ShowQrCode,
    subjectId: string
}

export interface AddQrSession {
    type: AppActionType.AddQrSession,
}

export interface RemoveQrSession {
    type: AppActionType.RemoveQrSession,
}

export interface SetOptions {
    type: AppActionType.SetOptions,
    options: FVInitParams
}

export interface SetDefaultOptions {
    type: AppActionType.SetDefaultOptions,
    defaultOptions: FVAdvancedInitParams[]
}

export interface SetActiveModalities {
    type: AppActionType.SetActiveModalities,
    activeModalities: IModality
}

export interface SetOperationState {
    type: AppActionType.SetOperationState,
    operationState: IOperationState
}

export interface SetChecksTitle {
    type: AppActionType.SetChecksTitle,
    checksTitle: string
}

export interface SetPrefix {
    type: AppActionType.SetPrefix,
    prefix: string | null
}

export type AppActions = AddSession | RemoveSession | ShowMessage | ShowOperationSummary | ShowQrCode | AddQrSession | RemoveQrSession | SetOptions | SetDefaultOptions | SetActiveModalities | SetOperationState | SetChecksTitle | SetPrefix;