import { Button, Dialog, Grid, TextField, Theme, styled, useMediaQuery } from "@mui/material"
import DialogTypographyText from "./Components/DialogTypographyText"
import DialogGridText from "./Components/DialogGridText"
import DialogGridButton from "./Components/DialogGridButton";
import DialogButton from "./Components/DialogButton";
import QRCode from "react-qr-code";
import { useSnackbar } from "notistack";

const ReadOnlyTextField = styled(TextField)(() => ({
    width: "100%",
}));

const CopyLinkButton = styled(Button)(() => ({
    height: "100%"
}));

const LinkGrid = styled(Grid)(() => ({
    paddingBottom: "1rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
}));

const QRGrid = styled(Grid)(() => ({
    paddingLeft: "35%",
    paddingRight: "35%",
    paddingTop: "1rem",
    paddingBottom: "1rem"
}));

interface IOperationOnPhoneDialog {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const OperationOnPhoneDialog: React.FC<IOperationOnPhoneDialog> = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const handleClose = () => {
        props.setOpen(false)
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(window.location.href)
        enqueueSnackbar("Link copied", {variant: "info"})
    }

    return (
        <Dialog onClose={handleClose} open={props.open} maxWidth="sm">
            <Grid container>
                <Grid container>
                    <DialogGridText item xs={12} textAlign={"center"}>
                        <DialogTypographyText fontWeight={500} fontSize={"1.25rem"} style={{paddingBottom: "10px", paddingTop: "1rem"}}>
                            {isMobile?
                            "Continue operation on your other phone or table devices"
                            : "Continue operation on your phone or tablet devices"}
                            
                        </DialogTypographyText>
                    </DialogGridText>
                    <DialogGridText item xs={12} textAlign={"center"}>
                        <DialogTypographyText style={{paddingBottom: "1.5rem"}}>
                            Copy the link to your browser by clicking on the button
                        </DialogTypographyText>
                    </DialogGridText>
                    <LinkGrid container>
                        <Grid item xs={9} textAlign={"left"}>
                            <ReadOnlyTextField
                                id="textfield-read-only"
                                defaultValue={window.location.href}
                                inputProps={{
                                    style: {paddingTop: "7px", paddingBottom: "7px"}
                                }}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3} textAlign={"right"}>
                            <CopyLinkButton onClick={handleCopyLink} variant="contained" color="primary">
                                Copy link
                            </CopyLinkButton>
                        </Grid>
                    </LinkGrid>
                    <DialogGridText item xs={12} textAlign={"center"} style={{paddingTop: "0.5rem"}}>
                        <DialogTypographyText fontWeight={500} >
                            Or scan QR code with your phone camera
                        </DialogTypographyText>
                    </DialogGridText>
                    <QRGrid item xs={12} textAlign={"center"}>
                        <QRCode
                            size={128}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={window.location.href}
                            viewBox={`0 0 256 256`}
                        />
                    </QRGrid>
                </Grid>
                <DialogGridButton container>
                    <Grid item xs={12} >
                        <DialogButton variant="outlined" color="secondary" onClick={handleClose}>
                            Close
                        </DialogButton>
                    </Grid>
                </DialogGridButton>
            </Grid>
        </Dialog>
    );
}

export default OperationOnPhoneDialog