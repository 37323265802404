import { useMediaQuery, Grid, Theme } from "@mui/material"
import { FVInitParams } from "neurotec-megamatcherid-management-client"
import { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { NavigationContext } from "../../../components/Navigation/store/context"
import { setOptionsActive } from "../../../components/Navigation/store/reducer"
import OperationButtonContained from "../../../components/Operations/styled/OperationButtonContained"
import OperationButtonOutlined from "../../../components/Operations/styled/OperationButtonOutlined"
import OperationTextGrid from "../../../components/Operations/styled/OperationTextGrid"
import OperationTypographyInstruction from "../../../components/Operations/styled/OperationTypographyInstruction"
import OperationTypographyText from "../../../components/Operations/styled/OperationTypographyText"
import { mmidInitParamsStorage } from "../../../config/const"
import { getManagementInfo, getInitParamsByManagementConfig } from "../../../helpers/startOperation"
import { useLocalStorage, initialFvParameters } from "../../../helpers/useLocalStorage"
import { startSession } from "../../../helpers/webRTC"
import { AppContext } from "../../../store/context"
import { showOperationSummary, addSession, showMessage, addQrSession } from "../../../store/reducer"
import { IOperationsStatus, Operations } from "../../../types/FaceVerification"
import { ModalityType } from "../../Enroll/Enroll"
import { RefVideoStream } from "../../VideoStream/BiometricFaceStream"

interface IVerifyFromQrSideBar {
    checkState: IOperationsStatus
    setCheckState: React.Dispatch<React.SetStateAction<IOperationsStatus>>
    getNextModality: () => void
    activeModality: ModalityType | null
    childRef: React.RefObject<RefVideoStream>
}

const VerifyFromQrCodeSideBar: React.FC<IVerifyFromQrSideBar> = (props) => {
    const { state, dispatch } = useContext(AppContext)
    const history = useHistory()
    const navigationDispatch = useContext(NavigationContext).dispatch
    const [options] = useLocalStorage<FVInitParams>(mmidInitParamsStorage, initialFvParameters);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    useEffect(() => {
        if (props.checkState === IOperationsStatus.SUCCESS && props.activeModality === ModalityType.QR_SCAN) {

        }
        if (props.checkState === IOperationsStatus.SUCCESS && props.activeModality !== ModalityType.QR_SCAN) {
            props.getNextModality()
        }
        if (props.checkState === IOperationsStatus.ACTIVE) {
            navigationDispatch(setOptionsActive(false))
        } else {
            navigationDispatch(setOptionsActive(true))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.checkState, navigationDispatch])

    const restart = () => {
        dispatch(showOperationSummary(""));
        props.setCheckState(IOperationsStatus.IDLE)
    }

    const verifyFromTemplate = async () => {
        if (!state.options?.verificationTemplate)
            return;
        const managementInfo = await getManagementInfo();
        if (!managementInfo) {
            return;
        }

        let initParams: FVInitParams = getInitParamsByManagementConfig(managementInfo, options);
        initParams.verificationTemplate = state.options?.verificationTemplate;
        initParams.type = Operations.VERIFY_WITH_QR;
        startSession(Operations.VERIFY_WITH_QR, initParams).then(res => {
            props.setCheckState(IOperationsStatus.ACTIVE)
            dispatch(addSession(res.data, initParams));
        }).catch(err => {
            props.setCheckState(IOperationsStatus.IDLE)
            console.log(err)
            dispatch(showMessage({ message: "Unable to create session: " + err.message, type: "error" }))
        })
    }

    const startQrScanning = () => {
        dispatch(addQrSession());
    }

    const getButtons = () => {
        if (props.activeModality === ModalityType.QR_SCAN) {
            switch(props.checkState) {
                case IOperationsStatus.IDLE:
                    return (
                        <OperationButtonContained onClick={startQrScanning} color="primary" variant="contained">
                            Start Qr Scan
                        </OperationButtonContained>
                    ) 
                case IOperationsStatus.ACTIVE:
                    return (
                        <OperationButtonContained onClick={props.childRef.current?.cancel} color="primary" variant="contained">
                            Cancel
                        </OperationButtonContained>
                    ) 
                case IOperationsStatus.DONE:
                    return (
                        <>
                            <OperationButtonContained onClick={restart}color="primary" variant="contained">
                                Recapturing
                            </OperationButtonContained>
                            <OperationButtonOutlined color="primary" variant="outlined" onClick={() => {history.push("/")}}>
                                Home
                            </OperationButtonOutlined>
                        </>
                    )
            }
        }
        if (props.activeModality === ModalityType.OVERVIEW) {
            return (
                <OperationButtonContained onClick={() => history.push("/")} color="primary" variant="contained">
                    Finish
                </OperationButtonContained>
            )
        }
        switch(props.checkState) {
            case IOperationsStatus.IDLE:
                return (
                    <OperationButtonContained onClick={verifyFromTemplate} color="primary" variant="contained">
                        Start verification
                    </OperationButtonContained>
                ) 
            case IOperationsStatus.ACTIVE:
                return (
                    <OperationButtonContained onClick={props.childRef.current?.cancel} color="primary" variant="contained">
                        Cancel
                    </OperationButtonContained>
                ) 
            case IOperationsStatus.DONE:
                return (
                    <>
                        <OperationButtonContained onClick={restart}color="primary" variant="contained">
                            Recapturing
                        </OperationButtonContained>
                        <OperationButtonOutlined color="primary" variant="outlined" onClick={() => {history.push("/")}}>
                            Home
                        </OperationButtonOutlined>
                    </>
                )
            case IOperationsStatus.SUCCESS:
                return (
                    <OperationButtonContained onClick={verifyFromTemplate} color="primary" variant="contained">
                        Start verification
                    </OperationButtonContained>
                ) 
        }
    }

    const getModalityText = () => {
        switch(props.activeModality) {
            case ModalityType.QR_SCAN:
                return (
                    <Grid item xs={12}>
                        {!isMobile || (props.checkState !== IOperationsStatus.ACTIVE && props.checkState !== IOperationsStatus.SUCCESS)? 
                            <>
                                <OperationTypographyInstruction>
                                    Scan qr code
                                </OperationTypographyInstruction>
                                <OperationTypographyText>
                                    Adjust QR code to the center of the screen.
                                </OperationTypographyText>
                            </>
                            : null
                        }
                        {getButtons()}
                    </Grid>
                )
            case ModalityType.FACE_MODALITY:
                return (
                    <Grid item xs={12}>
                        {!isMobile || (props.checkState !== IOperationsStatus.ACTIVE && props.checkState !== IOperationsStatus.SUCCESS)? 
                            <>
                                <OperationTypographyInstruction>
                                    Take a selfie
                                </OperationTypographyInstruction>
                                <OperationTypographyText>
                                    Adjust your position to place your face into oval frame and press Start capture.
                                </OperationTypographyText>
                            </>
                            : null
                        }
                        {getButtons()}
                    </Grid>
                )
            case ModalityType.VOICE_MODALITY:
                return (
                    <Grid item xs={12}>
                        {!isMobile || (props.checkState !== IOperationsStatus.ACTIVE && props.checkState !== IOperationsStatus.SUCCESS)? 
                            <>
                                <OperationTypographyInstruction>
                                    Record your voice
                                </OperationTypographyInstruction>
                                <OperationTypographyText>
                                    Press <b>Start verification</b> and speak.
                                </OperationTypographyText>
                            </>
                            : null
                        }
                        {getButtons()}
                    </Grid>
                )
            case ModalityType.OVERVIEW:
                return (
                    <Grid item xs={12}>
                        {!isMobile || (props.checkState !== IOperationsStatus.ACTIVE && props.checkState !== IOperationsStatus.SUCCESS)? 
                            <>
                                <OperationTypographyInstruction>
                                    Preview captured data
                                </OperationTypographyInstruction>
                            </>
                            : null
                        }
                        {getButtons()}
                    </Grid>
                )
        }
    }

    return (
        <OperationTextGrid item md={2} xs={12} glow={(props.checkState !== IOperationsStatus.ACTIVE && isMobile)}>
            {getModalityText()}
        </OperationTextGrid>
    )
}

export default VerifyFromQrCodeSideBar