import { Grid, Theme, useMediaQuery } from "@mui/material";
import { FVInitParams } from "neurotec-megamatcherid-management-client";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { NavigationContext } from "../../../components/Navigation/store/context";
import { mmidInitParamsStorage } from "../../../config/const";
import { useLocalStorage, initialFvParameters } from "../../../helpers/useLocalStorage";
import { AppContext } from "../../../store/context";
import { IOperationsStatus, Operations } from "../../../types/FaceVerification";
import { ModalityType } from "../../Enroll/Enroll";
import { RefVideoStream } from "../../VideoStream/BiometricFaceStream";
import { setOptionsActive } from "../../../components/Navigation/store/reducer";
import { addSession, showMessage, showOperationSummary } from "../../../store/reducer";
import { getInitParamsByManagementConfig, getManagementInfo } from "../../../helpers/startOperation";
import { startSession } from "../../../helpers/webRTC";
import OperationButtonContained from "../../../components/Operations/styled/OperationButtonContained";
import OperationButtonOutlined from "../../../components/Operations/styled/OperationButtonOutlined";
import OperationTypographyInstruction from "../../../components/Operations/styled/OperationTypographyInstruction";
import OperationTypographyText from "../../../components/Operations/styled/OperationTypographyText";
import OperationTextGrid from "../../../components/Operations/styled/OperationTextGrid";

interface IVerifySideBar {
    subjectId: string
    checkState: IOperationsStatus
    setCheckState: React.Dispatch<React.SetStateAction<IOperationsStatus>>
    getNextModality: () => void
    activeModality: ModalityType | null
    childRef: React.RefObject<RefVideoStream>
}

const VerifySideBar: React.FC<IVerifySideBar> = (props) => {

    const {state, dispatch} = useContext(AppContext);
    const history = useHistory()
    const navigationDispatch = useContext(NavigationContext).dispatch
    const [options] = useLocalStorage<FVInitParams>(mmidInitParamsStorage, initialFvParameters);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    useEffect(() => {
        if (props.checkState === IOperationsStatus.SUCCESS) {
            props.getNextModality()
        }
        if (props.checkState === IOperationsStatus.ACTIVE) {
            navigationDispatch(setOptionsActive(false))
        } else {
            navigationDispatch(setOptionsActive(true))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.checkState, navigationDispatch])

    const restart = () => {
        dispatch(showOperationSummary(""));
        props.setCheckState(IOperationsStatus.IDLE)
    }

    const verify = async () => {
        const managementInfo = await getManagementInfo();
        if (!managementInfo) {
            return;
        }
        let initParams: FVInitParams = getInitParamsByManagementConfig(managementInfo, options);
        initParams.subjectId = props.subjectId;
        initParams.type = Operations.VERIFY;
        startSession(Operations.VERIFY, initParams).then(res => {
            props.setCheckState(IOperationsStatus.ACTIVE)
            dispatch(addSession(res.data, initParams));
        }).catch(err => {
            props.setCheckState(IOperationsStatus.IDLE)
            console.log(err)
            dispatch(showMessage({ message: "Unable to create session: " + err.message, type: "error" }))
        })
    }

    const handleSubjectId = () => {
        if (state.prefix) {
            if (props.subjectId.startsWith(state.prefix)) {
                return props.subjectId.substring(state.prefix.length + 1)
            }
        }
        return props.subjectId;
    }

    const getButtons = () => {
        if (props.activeModality === ModalityType.OVERVIEW) {
            return (
                <OperationButtonContained onClick={() => history.push("/")} color="primary" variant="contained">
                    Finish
                </OperationButtonContained>
            )
        }
        switch(props.checkState) {
            case IOperationsStatus.IDLE:
                return (
                    <OperationButtonContained onClick={verify} color="primary" variant="contained">
                        Start verification
                    </OperationButtonContained>
                ) 
            case IOperationsStatus.ACTIVE:
                return (
                    <OperationButtonContained onClick={props.childRef.current?.cancel} color="primary" variant="contained">
                        Cancel
                    </OperationButtonContained>
                ) 
            case IOperationsStatus.DONE:
                return (
                    <>
                        <OperationButtonContained onClick={restart}color="primary" variant="contained">
                            Recapturing
                        </OperationButtonContained>
                        <OperationButtonOutlined color="primary" variant="outlined" onClick={() => {history.push("/")}}>
                            Home
                        </OperationButtonOutlined>
                    </>
                )
        }
    }

    const getModalityText = () => {
        switch(props.activeModality) {
            case ModalityType.FACE_MODALITY:
                return (
                    <Grid item xs={12}>
                        {!isMobile || (props.checkState !== IOperationsStatus.ACTIVE && props.checkState !== IOperationsStatus.SUCCESS)? 
                            <>
                                <OperationTypographyInstruction>
                                    Take a selfie
                                </OperationTypographyInstruction>
                                <OperationTypographyText>
                                    Adjust your position to place your face into oval frame and press Start capture.
                                </OperationTypographyText>
                            </>
                            : null
                        }
                        {getButtons()}
                    </Grid>
                )
            case ModalityType.VOICE_MODALITY:
                return (
                    <Grid item xs={12}>
                        {!isMobile || (props.checkState !== IOperationsStatus.ACTIVE && props.checkState !== IOperationsStatus.SUCCESS)? 
                            <>
                                <OperationTypographyInstruction>
                                    Record your voice
                                </OperationTypographyInstruction>
                                <OperationTypographyText>
                                    Press <b>Start verification</b> and speak.
                                </OperationTypographyText>
                            </>
                            : null
                        }
                        {getButtons()}
                    </Grid>
                )
            case ModalityType.OVERVIEW:
                return (
                    <Grid item xs={12}>
                        {!isMobile || (props.checkState !== IOperationsStatus.ACTIVE && props.checkState !== IOperationsStatus.SUCCESS)? 
                            <>
                                <OperationTypographyInstruction>
                                    Preview captured data
                                </OperationTypographyInstruction>
                                <OperationTypographyText>
                                    <b>SubjectId:</b> {handleSubjectId()}
                                </OperationTypographyText>
                            </>
                            : null
                        }
                        {getButtons()}
                    </Grid>
                )
        }
    }

    return (
        <OperationTextGrid item md={2} xs={12} glow={(props.checkState !== IOperationsStatus.ACTIVE && isMobile)}>
            {getModalityText()}
        </OperationTextGrid>
    )
}

export default VerifySideBar