import { Box, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { AppContext } from '../../store/context'
import { showMessage } from '../../store/reducer'
import { makeStyles } from "@mui/styles";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { THEME } from '../../config'

const useStyles = makeStyles(() => ({
    box: {
        position: "absolute",
        transform: "translateY(-50%)",
        top: "5vh",
        overflowWrap: "break-word",
        justifyContent: 'center',
        display: 'flex',
        borderRadius: "7px",
        zIndex: "100"
    },
    iconBox: {
        align: "center"
    },
    typography: {
        marginTop: "2px",
        marginBottom: "5px",
        marginLeft: "15px",
        marginRight: "15px"
    }
}))

const Message: React.FC = () => {

    const { state, dispatch } = useContext(AppContext)
    const classes = useStyles()

    useEffect(() => {
        return (() => {dispatch(showMessage({ type: 'info', message: '' }))})
    }, [dispatch])

    const getMessageColor = () => {
        if (state.message.type === 'info') {
            return THEME.palette.info.main;
        }
        else if (state.message.type === 'success') {
            return THEME.palette.success.main;
        }
        else if (state.message.type === 'warning') {
            return THEME.palette.warning.main;
        }
        else if (state.message.type === "failure") {
            return THEME.palette.error.dark
        } 
        return "red";
    }

    const getMessageIcon = () => {
        if (state.message.type === 'info' || state.message.type === 'success' || state.message.type === "warning") {
            return <InfoOutlinedIcon fontSize="medium" style={{marginRight: "20px", position: "relative", top: "5px"}} />
        } else  {
            return <ErrorOutlineIcon fontSize="medium" style={{marginRight: "20px", position: "relative", top: "5px"}} />
        } 
    }

    const renderMessage = () => {
        if (!state.message.message) return null;
        if (!state.session) {
            setTimeout(() => {
                dispatch(showMessage({ type: 'info', message: '' }))
            }, 30000);
        }
        return (
            <Typography
                component={'span'}
                align='center'
                fontSize="15pt"
                color="white"
                className={classes.typography}
            >
                <Box className={classes.iconBox} style={{userSelect: "none"}}>
                    {getMessageIcon()}
                    {state.message.message}
                </Box>
            </Typography>
        )
    }

    return (
        <Box className={classes.box} style={{backgroundColor: getMessageColor()}}>
            {renderMessage()}
        </Box>
    )
}

export default Message
