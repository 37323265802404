import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import OperationButton from "./OperationButton";
import { useHistory } from "react-router-dom";
import VerifyDialog from "../../containers/Verify/components/VerifyDialog";
import { initialFvParameters, useLocalStorage } from "../../helpers/useLocalStorage";
import { FVInitParams } from "neurotec-megamatcherid-management-client";
import { mmidInitParamsStorage } from "../../config/const";
import EnrollDialog from "../../containers/Enroll/components/EnrollDialog";
import { IOperationState, ModalityStateStatus } from "../../store/actions";
import { ModalityType } from "../../containers/Enroll/Enroll";
import { setActiveModalities, setChecksTitle, setOperationState } from "../../store/reducer";
import { AppContext } from "../../store/context";

const OperationsButtons: React.FC = () => {

    const history = useHistory();
    const {state, dispatch} = useContext(AppContext);
    const [openVerify, setOpenVerify] = useState(false)
    const [openEnroll, setOpenEnroll] = useState(false)
    const options = useLocalStorage<FVInitParams>(mmidInitParamsStorage, initialFvParameters)[0];

    const handleEnrollButton = () => {
        setOpenEnroll(true)
    }

    const handleVerifyButton = () => {
        setOpenVerify(true)
    }

    const handleChecksButton = () => {
        let operationStatus: IOperationState = {subjectId: "", modalityState: []}
        operationStatus.modalityState.push({modality: ModalityType.FACE_MODALITY, state: ModalityStateStatus.ACTIVE})
        operationStatus.modalityState.push({modality: ModalityType.OVERVIEW, state: ModalityStateStatus.TO_BE_DONE})
        dispatch(setOperationState(operationStatus))
        dispatch(setActiveModalities({faceModality: true, voiceModality: false}))
        history.push("/check")
    }

    useEffect(() => {
        if (options?.livenessMode && options?.livenessMode > 0) {
            if (options.checkIcaoCompliance) {
                dispatch(setChecksTitle("Face Check Liveness & ICAO"))
            }
            else {
                dispatch(setChecksTitle("Face Check Liveness"))
            }
        } else {
            if (options?.checkIcaoCompliance) {
                dispatch(setChecksTitle("Face Check ICAO"))
            }
            else {
                dispatch(setChecksTitle("Face Checks Liveness & ICAO"))
            }
        }
    }, [options.livenessMode, options.checkIcaoCompliance, dispatch])

    return (
        <>
            <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                    <OperationButton onClick={handleEnrollButton} title="Enroll Subject"/>
                </Grid>
                <Grid item md={4} xs={12}>
                    <OperationButton onClick={handleVerifyButton} title="Verify against subject"/>
                </Grid>
                <Grid item md={4} xs={12}>
                    <OperationButton onClick={handleChecksButton} title={state.checksTitle} disable={options?.livenessMode === 0 && !options?.checkIcaoCompliance}/>
                </Grid>
            </Grid>
            <VerifyDialog open={openVerify} setOpen={setOpenVerify}/>
            <EnrollDialog open={openEnroll} setOpen={setOpenEnroll}/>
        </>
    );
}

export default OperationsButtons;