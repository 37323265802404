import { Grid, Typography, TypographyProps, styled } from "@mui/material";

const StyledJanusTypographyTitle = styled(Typography)(()=>({
    fontWeight: "500"
}))

const StyledGrid = styled(Grid)(() => ({
    fontWeight: "500",
    borderBottomStyle: "solid",
    borderBottomColor: "#D7D7D7"
}))

interface ITitleBar extends TypographyProps {
    beginText?: string,
    endText?: string
}

const TitleBar: React.FC<ITitleBar> = (props) => {
    return (
        <StyledGrid container style={{justifyContent: "space-between"}}>  
            {
                props.beginText ?
                <StyledJanusTypographyTitle display={"inline"} align="left" {...props} >
                    {props.beginText}
                </StyledJanusTypographyTitle> : null
            }
            {
                props.endText ?
                <StyledJanusTypographyTitle display={"inline"} align="right" {...props} >
                    {props.endText}
                </StyledJanusTypographyTitle> : null
            }
        </StyledGrid>
    )
}

export default TitleBar

