import { Grid } from "@mui/material"
import CheckboxBox from "./CheckboxBox"

interface ICheckboxBoxContainerProps {
    faceModality: boolean,
    voiceModality: boolean
    faceModalityDisabled: boolean,
    voiceModalityDisabled: boolean
    setFaceModality: (c: boolean) => void
    setVoiceModality: (c: boolean) => void
}

const CheckboxBoxContainer: React.FC<ICheckboxBoxContainerProps> = (props) => {

    return (
        <>
        <Grid item xs={6} style={{paddingRight: "0rem", display: "flex", justifyContent: "center"}}>
            <CheckboxBox
                checked={props.faceModality}
                setChecked={(c) => props.setFaceModality(c)}
                disabled={props.faceModalityDisabled}
                type={"face"}
            />
        </Grid>
        <Grid item xs={6} style={{paddingRight: "0rem", display: "flex", justifyContent: "center"}}>
            <CheckboxBox
                checked={props.voiceModality}
                setChecked={(c) => props.setVoiceModality(c)}
                disabled={props.voiceModalityDisabled}
                type={"voice"}
            />
        </Grid>
        </>
    )
}

export default CheckboxBoxContainer