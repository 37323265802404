import { ForwardedRef, useCallback, useContext, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import ConfirmationDialog from "../../components/Misc/Dialog/ConfirmationDialog"
import OperationOnPhoneDialog from "../../components/Misc/Dialog/OperationOnPhoneDialog"
import BiometricDiv from "../VideoStream/BiometricDiv"
import React from "react";
import { IOperationsStatus, Status } from "../../types/FaceVerification";
import { removeSession, showMessage, showOperationSummary } from "../../store/reducer";
import { AppContext } from "../../store/context";
import { connectToJanus, setupDataChannel, setupWebRTC, stopSession } from "../../helpers/webRTC";
import { FVInitParams, JanusResponse } from "neurotec-megamatcherid-management-client";
import { THEME } from "../../config";
import { Grid, Theme, useMediaQuery } from "@mui/material";
import { LiveAudioVisualizer } from "../../helpers/visualizeAudio/LiveAudioVisualizer";
import { ModalityType } from "../Enroll/Enroll";

const constraints = {
    audio: true,
    video: false
};

export interface RefAudioStream {
    cancel?: () => void
    ref?: any
    setOperationStatus?: React.Dispatch<React.SetStateAction<IOperationsStatus>>
    setOperationUUID?: (uuid: string) => void,
}

const BiometricVoiceStream: React.FC<RefAudioStream> = React.forwardRef((props: RefAudioStream, ref: ForwardedRef<RefAudioStream>) => {
    
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
    const divRef = useRef<HTMLDivElement>(null);
    const audioRef = useRef<HTMLAudioElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const rtcConnection = useRef<RTCPeerConnection | null>(null);
    const { state, dispatch } = useContext(AppContext)
    const [openCameraAccess, setOpenCameraAccess] = useState(false);
    const audioRecorder = useRef<MediaRecorder | null>(null)
    const [openOperationOnMicrophone, setOpenOperationOnMicrophone] = useState(false);
    const [visualizerSize, setVisualizerSize] = useState<{width: number, height: number}> ({width: 100, height: 100})

    useImperativeHandle(ref, () => ({
        cancel: () => {
            closeSession(Status.CANCELED)
            if (audioRecorder.current && audioRecorder.current.state === "recording") {
                audioRecorder.current.pause();
            }
        }
    }));
    const setOperationStatus = props.setOperationStatus
    const setOperationUUID = props.setOperationUUID

    const handleMicrophoneAccess = () => {
        setOpenCameraAccess(false)
        setOpenOperationOnMicrophone(true)
    }

    const setupCanvas = useCallback(() => {
        if (divRef.current && canvasRef.current) {
            let padding = 0
            let canvas = canvasRef.current;
            let div = divRef.current;
            canvas.width = div.clientWidth + ( -padding * 2);
            canvas.height = div.clientHeight;
            canvas.style.top = div.offsetTop + "px";
            canvas.style.left = div.offsetLeft + padding + "px";
            setVisualizerSize({width: canvas.width / 2.4, height: canvas.height})
        }
    }, []);

    const setupAudio = useCallback(() => {
        if (audioRef.current && canvasRef.current) {
            const audio = audioRef.current;
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
                    audio.addEventListener("loadeddata", setupCanvas);
                    audio.srcObject = stream;
                    if (MediaRecorder.isTypeSupported("audio/webm")) {
                        audioRecorder.current = new MediaRecorder(stream, {mimeType: "audio/webm"});
                    } else {
                        audioRecorder.current = new MediaRecorder(stream, {mimeType: "audio/mp4"});
                    }

                }).catch((error) => {
                    setOpenCameraAccess(true)
                    if ((error as DOMException).name === "NotAllowedError") {
                        dispatch(showMessage({ message: "Permission denied", type: "error" }));
                    } else {
                        dispatch(showMessage({ message: (error as DOMException).message, type: "error" }));
                    }
                    console.error(error)
                })
            }
            else {
                setOpenCameraAccess(true)
                dispatch(showMessage({ message: "WebRTC not available", type: "error" }));
            }
        }
    }, [dispatch, setupCanvas]);

    const closeSession = useCallback((status?: Status) => {
        if (setOperationStatus) {
            if (status === Status.SUCCESS) {
                setOperationStatus(IOperationsStatus.SUCCESS)
            } else {
                setOperationStatus(IOperationsStatus.DONE)
                if (audioRecorder.current) {
                    audioRecorder.current.pause()
                }
            }
        }
        console.debug("Closing sessions start");
        stopSession(state.session, rtcConnection.current).then(() => {
            if (status === Status.CANCELED)
                dispatch(showMessage({ message: "", type: "info" }));
        });
        dispatch(removeSession());
        rtcConnection.current = null;
        console.debug("Closing sessions");
    }, [dispatch, rtcConnection, state.session, setOperationStatus]);

    const onSuccessCallback = useCallback((connection: JanusResponse | undefined) => {
        console.debug("success callback")
        const operationId = connection?.operation_id ? connection.operation_id : "";
        if (setOperationUUID) {
            setOperationUUID(operationId)
        }
    }, [setOperationUUID]);

    const setupBiometricStream = useCallback(async () => {
        if (state.session && audioRef.current && canvasRef.current && divRef.current) {
            let audio = audioRef.current;
            let canvas = canvasRef.current;
            let div = divRef.current;
            let stream: MediaStream = audio.srcObject as MediaStream;
            if (stream) {
                try {
                    dispatch(showMessage({ message: "Loading...", type: "info" }));
                    let janusConnection: JanusResponse | undefined;
                    rtcConnection.current = await setupWebRTC(state.session, stream);
                    await setupDataChannel(
                        rtcConnection.current,
                        state.session,
                        undefined,
                        audio,
                        canvas,
                        div,
                        (message) => { dispatch(showMessage(message)) },
                        (status?: Status) => closeSession(status),
                        () => onSuccessCallback(janusConnection),
                        state.options?.livenessMode);
                    janusConnection = await connectToJanus(rtcConnection.current, state.session, state.options as FVInitParams, ModalityType.VOICE_MODALITY)
                    if (!janusConnection) {
                        dispatch(showMessage({ message: "Unable to setup connection", type: "error" }));
                        closeSession();
                        return;
                    }
                } catch (err: any) {
                    closeSession();
                    let errorMessage: string = "Unable to start session";
                    console.log(err.response)
                    if (err.isAxiosError && err.response.data && err.response.data.message) {
                        errorMessage = err.response.data.message
                    }
                    console.log(errorMessage);
                    dispatch(showMessage({ message: errorMessage, type: "error" }));
                }
            } else {
                closeSession();
                dispatch(showMessage({ message: "No stream available", type: "error" }));
            }
        }
    }, [closeSession, dispatch, onSuccessCallback, state.options, state.session]);

    useEffect(() => {
        let ref: HTMLAudioElement | undefined = undefined
        if (audioRef.current)
            ref = audioRef.current

        return () => {
            if (!(ref?.srcObject as MediaStream) || ref === undefined)
                return
            (ref?.srcObject as MediaStream).getTracks().forEach((track) => {
                track.enabled=false
                track.stop()
            });
            ref.srcObject = null

            if (audioRecorder.current) {
                audioRecorder.current.stop()
                audioRecorder.current.stream.getTracks().forEach((track) => {
                    track.enabled=false
                    track.stop()
                });
                audioRecorder.current = null
            }
        }
    }, []);

    useLayoutEffect(() => {
        if (audioRef.current && !audioRef.current.srcObject) {
            setupAudio();
        }
            
        if (state.session) {
            if (audioRecorder.current) {
                if (audioRecorder.current.state === "inactive") {
                    audioRecorder.current.start()
                }
                if (audioRecorder.current.state === "paused") {
                    audioRecorder.current.resume()
                }
            }
            if (setOperationStatus)
                setOperationStatus(IOperationsStatus.ACTIVE)
            dispatch(showOperationSummary(""))
            setupBiometricStream()
        }

        setupCanvas();
        window.addEventListener("visibilitychange", (e) => {
            if (document.visibilityState === "hidden") {
                if (audioRef.current && audioRef.current?.srcObject) {
                    (audioRef.current?.srcObject as MediaStream).getTracks().forEach((track) => {
                        track.enabled=false
                        track.stop()
                        closeSession()
                    })
                }
                if (audioRecorder.current && audioRecorder.current.state === "recording") {
                    audioRecorder.current?.pause()
                } 
            }

            if (document.visibilityState === "visible") {
                setupAudio()
            }
        })
        window.addEventListener("resize", setupCanvas);
        return () => window.removeEventListener("resize", setupCanvas);
    }, [setupBiometricStream, setupCanvas, setupAudio, state.session, dispatch, setOperationStatus, closeSession])

    return (
        <BiometricDiv ref={divRef}>
            {
                canvasRef.current !== undefined?
                <Grid container style={{paddingLeft: isMobile? "1rem" : "3rem", paddingRight: isMobile? "1rem" : "3rem"}}>
                    <Grid item xs={6} style={{transform: "scaleX(-1)", paddingLeft: "1px"}}>
                        <LiveAudioVisualizer
                            mediaRecorder={audioRecorder.current}
                            width={visualizerSize.width}
                            height={visualizerSize.height}
                            barColor={THEME.palette.primary.main}
                            barWidth={15}
                            maxDecibels={-55}
                            minDecibels={-80}
                            fftSize={8192}
                            smoothingTimeConstant={0.4}
                        />
                    </Grid>
                    <Grid item xs={6} height={"100%"}>
                        <LiveAudioVisualizer
                            mediaRecorder={audioRecorder.current}
                            width={visualizerSize.width}
                            height={visualizerSize.height}
                            barColor={THEME.palette.primary.main}
                            barWidth={15}
                            maxDecibels={-55}
                            minDecibels={-80}
                            fftSize={8192}
                            smoothingTimeConstant={0.4}
                        />
                    </Grid>
                </Grid>: null
            }

            <canvas ref={canvasRef} style={{ position: "absolute", zIndex: 2, top: 0, left: 0 }} />
            <audio ref={audioRef}/>
            <ConfirmationDialog
                open={openCameraAccess}
                setOpen={setOpenCameraAccess}
                confirmColor="primary"
                title="Microphone access"
                text="Microphone access is necessary to perform this operation. Allow camera access or continue on mobile"
                cancelText="Close"
                confirmText="Continue on mobile"
                confirm={handleMicrophoneAccess}
            />
            <OperationOnPhoneDialog
                open={openOperationOnMicrophone}
                setOpen={setOpenOperationOnMicrophone}
            />
        </BiometricDiv>
    )
})

export default BiometricVoiceStream