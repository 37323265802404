import React from "react";
import { SettingType } from "../../../containers/Settings/Settings";

export interface NavigationState {
    page: React.FC<any> | null,
    selected: boolean,
    isDrawerOpen: boolean,
    isMobile: boolean,
    settingType: SettingType,
    optionsActive: boolean,
    developerOptions: boolean,
}
export const initNavigationState: NavigationState = {
    page: null,
    selected: false,
    isDrawerOpen: false,
    isMobile: false,
    settingType: SettingType.CAPTURING,
    optionsActive: true,
    developerOptions: false,
}