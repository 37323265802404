import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../store/context'
import { useHistory } from 'react-router-dom'
import EnrollSideBar from './components/EnrollSideBar'
import { Grid } from '@mui/material'
import { IOperationsStatus } from '../../types/FaceVerification'
import { RefVideoStream } from '../VideoStream/BiometricFaceStream'
import OperationContainer from '../../components/Operations/styled/OperationContainer'
import OperationTitle from '../../components/Operations/styled/OperationTitle'
import { ModalityStateStatus } from '../../store/actions'
import { setOperationState } from '../../store/reducer'
import Overview from '../Overview/Overview'
import FaceOperation from './Modalities/FaceOperation'
import VoiceOperation from './Modalities/VoiceOperation'

export enum ModalityType {
    QR_SCAN = "QR_SCAN",
    FACE_MODALITY = "FACE_MODALITY",
    VOICE_MODALITY = "VOICE_MODALITY",
    OVERVIEW = "OVERVIEW"
}

const Enroll: React.FC = () => {

    const [activeModality, setActiveModality] = useState<ModalityType | null>(null);
    const {state, dispatch} = useContext(AppContext)
    const history = useHistory();
    const [checkState, setCheckState] = useState<IOperationsStatus>(IOperationsStatus.IDLE);
    const childRef = React.useRef<RefVideoStream>(null)
    const [faceOperationUUID, setFaceOperationUUID] = useState("");
    const [voiceOperationUUID, setVoiceOperationUUID] = useState("");

    useEffect(() => {
        if (state.activeModalities.faceModality) {
            setActiveModality(prev => {
                if (prev == null) {
                    return ModalityType.FACE_MODALITY
                } else {
                    return prev
                }
            })
        }
        if (state.activeModalities.voiceModality) {
            setActiveModality(prev => {
                if (prev == null) {
                    return ModalityType.VOICE_MODALITY
                } else {
                    return prev
                }
            })
        }
    }, [state.activeModalities.faceModality,state.activeModalities.voiceModality, history])

    useEffect(() => {
        if (state.activeModalities.faceModality === false && state.activeModalities.voiceModality === false) {
            history.push("/")
        }
    })
    
    const getName = (): string => {
        switch (activeModality) {
            case ModalityType.FACE_MODALITY:
                return "Face enrollment"
            case ModalityType.VOICE_MODALITY:
                return "Voice enrollment"
            case ModalityType.OVERVIEW:
                return "Overview"
            default: 
                return "Enrollment"
        }
    }

    const getNextModality = () => {
        let index = -1
        const operationState = state.operationState.modalityState.map((s, i) => {
            if (s.state === ModalityStateStatus.ACTIVE) {
                index = i + 1;
                return {
                    modality: s.modality,
                    state: ModalityStateStatus.DONE
                }
            }
            if (i === index) {
                return {
                    modality: s.modality,
                    state: ModalityStateStatus.ACTIVE
                }
            }
            return s
        })
        dispatch(setOperationState({subjectId: state.operationState.subjectId, modalityState: operationState}))
        if (activeModality === ModalityType.FACE_MODALITY) {
            if (state.activeModalities.voiceModality) {
                setActiveModality(ModalityType.VOICE_MODALITY)
                setCheckState(IOperationsStatus.IDLE)
            } else {
                setActiveModality(ModalityType.OVERVIEW)
                setCheckState(IOperationsStatus.IDLE)
            }
        } 
        if (activeModality === ModalityType.VOICE_MODALITY) {
            setActiveModality(ModalityType.OVERVIEW)
            setCheckState(IOperationsStatus.IDLE)
        }
    }

    const getActiveState = () => {
        switch (activeModality) {
            case ModalityType.FACE_MODALITY:
                return (
                    <FaceOperation
                        setCheckState={setCheckState}
                        childRef={childRef}
                        setOperationUUID={setFaceOperationUUID}
                    />
                )
            case ModalityType.VOICE_MODALITY:
                return (
                    <VoiceOperation
                        setCheckState={setCheckState}
                        childRef={childRef}
                        setOperationUUID={setVoiceOperationUUID}
                    />
                )
            case ModalityType.OVERVIEW:
                return  (
                    <Overview
                        faceOperationUUID={faceOperationUUID}
                        voiceOperationUUID={voiceOperationUUID}
                    />
                )
        }
    }

    return (
        <OperationContainer maxWidth={false}>
            <Grid container style={{paddingLeft: "0px", paddingRight: "0px"}}>
                <OperationTitle>
                    {getName()}
                </OperationTitle>
                    {getActiveState()}
                <EnrollSideBar
                    subjectId={state.operationState.subjectId}
                    activeModality={activeModality}
                    checkState={checkState}
                    setCheckState={setCheckState}
                    getNextModality={getNextModality}
                    childRef={childRef}
                />
            </Grid>
        </OperationContainer>
    );
}

export default Enroll;
