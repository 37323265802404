import { Grid, Typography, styled } from "@mui/material"
import { useTheme } from '@mui/material/styles';
import GoBack from "../../Navigation/GoBack";

const GridContainer = styled(Grid)(({ theme }) => ({
    marginTop: useTheme().spacing(2),
    height: "5%",
    [theme.breakpoints.down("md")]: {
        display: "none"
    }
}))

const TypographyStyled = styled(Typography)(() => ({
    fontWeight: 500,
    fontSize: "2.125rem"
}))

interface IOperationTitleProps {
    children: string
}

const OperationTitle: React.FC<IOperationTitleProps> = (props) => {

    return (
        <GridContainer container>
            <GoBack />
            <TypographyStyled>
                {props.children}
            </TypographyStyled>
        </GridContainer>
    )
}

export default OperationTitle