import { Paper, TableBody, TableContainer, TableHead, TableRow, Theme, useMediaQuery } from "@mui/material";
import TextCell from "../components/TextCell";
import { ISliderInterface } from "../components/SliderCell";
import { DefaultButton, SettingTable, TableCellButton, settingUseStyles } from "../Settings";
import SliderRow from "../components/SliderRow";
import ConfirmationDialog from "../../../components/Misc/Dialog/ConfirmationDialog";
import { useState } from "react";
import { useSnackbar } from "notistack";

interface IVerification {
    findDefaultFVSliderInterface: (name: string) => ISliderInterface | undefined,
    findFVSliderInterface: (name: string) => (ISliderInterface | undefined),
    setNewOption: (op: ISliderInterface) => void,
    resetValueToDefault: (name: string) => void,
}

const Verification: React.FC<IVerification> = (props) => {

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const classes = settingUseStyles();
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar();

    const handleResetClick = () => {
        enqueueSnackbar("Verification settings reset", {variant: "info"})
        props.resetValueToDefault("MatchingThreshold")
    }

    return (
        <>
        <TableContainer component={Paper} className={classes.container}>
            <SettingTable>
                <TableHead>
                    <TableRow>
                        <TextCell head title="Verification" subtitle=""/>
                        <TableCellButton align="right" colSpan={isMobile? 2 : 3}>
                            <DefaultButton variant="text" color="primary" onClick={() => setOpenDialog(true)}>
                                Reset to default
                        </DefaultButton>
                        </TableCellButton>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Matching"
                        subtitle="Minimum score when Verify functions determines that compared faces are a match."
                        title="Threshold range"
                        option={props.findFVSliderInterface("MatchingThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("MatchingThreshold")}
                        setOption={props.setNewOption}
                        minValue={36}
                        maxValue={96}
                        type="infinite"
                        active
                        lockMinValue
                    />
                </TableBody>
            </SettingTable>
        </TableContainer>
        <ConfirmationDialog
            open={openDialog}
            setOpen={setOpenDialog}
            title="Reset verification settings"
            text="Reset verification settings to default values?"
            cancelText="Cancel"
            confirmText="Yes"
            confirm={handleResetClick}
            confirmColor="error"
        />
        </>
    );
}

export default Verification;