import React from "react";
import guidelineImage1 from "../../assets/guidelinesImages/guideline1.jpg"
import guidelineImage2 from "../../assets/guidelinesImages/guideline2.jpg"
import guidelineImage3 from "../../assets/guidelinesImages/guideline3.jpg"
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';
import GuidelineImage from "./GuidelineImage";

const useStyles = makeStyles((theme: { spacing: (arg0: number) => any; }) => ({
    Button: {
        marginTop: useTheme().spacing(3),
        width: "20rem",
        height: "6rem",
        borderRadius: "15px"
    },
    textTitle: {
        fontWeight: 500,
        fontSize: "1.25rem",
        color: "#303030"
    },
    text: {
        fontWeight: 400,
        fontSize: "1rem",
        color: "#303030"
    }
}));

const GeneralGuidelines: React.FC = () => {

    const classes = useStyles();
    
    return (
        <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
                <GuidelineImage src={guidelineImage1}/>
                <Typography className={classes.textTitle}>
                    Face Capture
                </Typography>
                <Typography className={classes.text}>
                    This demo can capture, enroll and verify human faces from a browser using your device's camera.
                </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <GuidelineImage src={guidelineImage2}/>
                <Typography className={classes.textTitle}>
                    Ambient lighting
                </Typography>
                <Typography className={classes.text}>
                    Before any operation please check that ambient lighting in not too bright or too dark and there are no shadows or glare on your face.
                </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <GuidelineImage src={guidelineImage3}/>
                <Typography className={classes.textTitle}>
                    Neutral face expression
                </Typography>
                <Typography className={classes.text}>
                    When capturing your face, neutral face expression is required: no smilling, eyes open and mouth closed, no mask, sunglasses or headwear.
                </Typography>
            </Grid>
        </Grid>
    );
}

export default GeneralGuidelines;