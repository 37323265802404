import { Grid, styled } from "@mui/material";
import Message from "../../../components/Message/Message";

const MainGrid = styled(Grid)(({ theme }) => ({
    height: "80vh",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingTop: "0.5rem",
    borderRadius: "5px",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    alignContent: "center",
    position: "relative",
    [theme.breakpoints.down("md")]: {
        height: "80vh"
    }
}));

interface IOverviewGridContainerProps {
    children?: JSX.Element,
    item?: boolean,
    md?: number,
    xs?: number,
    style?: any
}

const OverviewGridContainer = ({children, item, md, xs}: IOverviewGridContainerProps): JSX.Element => {

    return (
        <MainGrid item={item} md={md} xs={xs}>
            {children}
            <Message />
        </MainGrid>
    )
}

export default OverviewGridContainer