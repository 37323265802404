import { IOperationsStatus } from "../../../types/FaceVerification"
import { RefVideoStream } from "../../VideoStream/BiometricFaceStream"
import VideoGrid from "../../VideoStream/VideoGrid"
import { AppContext } from "../../../store/context"
import { NavigationContext } from "../../../components/Navigation/store/context"
import React, { useContext, useEffect } from "react"
import { setOptionsActive } from "../../../components/Navigation/store/reducer"
import { showMessage } from "../../../store/reducer"
import QrStream from "../../VideoStream/QrStream"

interface IFaceOperation {
    setCheckState: React.Dispatch<React.SetStateAction<IOperationsStatus>>
    childRef: React.RefObject<RefVideoStream>
}

const QrStreamOperation: React.FC<IFaceOperation> = (props) => {

    const dispatch = useContext(AppContext).dispatch;
    const navigationDispatch = useContext(NavigationContext).dispatch

    useEffect(() => {
        let effectRef: RefVideoStream | undefined = undefined
        if (props.childRef.current)
            effectRef = props.childRef.current

        return () => {
            if (effectRef !== undefined && effectRef.cancel)
                effectRef.cancel()
            navigationDispatch(setOptionsActive(true))
            dispatch(showMessage({ message: "", type: "info" }))
        }
    }, [navigationDispatch, dispatch, props.childRef])

    return (
        <VideoGrid item md={10} xs={12}>
            <QrStream
                ref={props.childRef}
                setOperationStatus={props.setCheckState}
            />
        </VideoGrid>
    )
}

export default QrStreamOperation