import icon from '../../assets/icon.png'
import React from 'react'
import { Box, Button, styled, Theme, Typography, useMediaQuery } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { THEME } from '../../config';

const LogoImage = styled('img')({
    width: "auto",
    height: "100%",
});

const LinkButton = styled(Button)({
    height: "100%",
    color: THEME.palette.secondary.main,
    fontSize: "1rem",
    fontWeight: 500,
    textAlign: "center",
    textDecoration: "none",
    ":hover": {
        opacity: 1
    }
})

interface ILogoProps {
    nav?: boolean
}

const Logo: React.FC<ILogoProps> = (props) => {

    const history = useHistory()
    const { pathname } = useLocation();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <LinkButton color="primary" onClick={() => history.push("/")}>
            <Box sx={{
                display: "inline-flex",
                alignItems: "center",
                minWidth: {
                    // xs: "227px",
                    // md: "400px"
                },
                height: "100%",
                width: "auto"
            }}>
                <LogoImage src={icon} alt="" />
                {
                    isMobile && (pathname === "/enroll" || pathname === "/verify" || pathname === "/check" || pathname === "/verifyfromqrcode")? null:
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column"
                        }}>
                            <Typography sx={{
                                color: "#1b1c1e",
                                fontSize: {
                                    xs: "1.1rem",
                                    sm: "1.3rem"
                                },
                                fontWeight: "500",
                            }}>MegaMatcher ID Demo</Typography>
                        </Box>
                }
            </Box>
        </LinkButton>
    )
}

export default Logo
