import { Container, styled } from "@mui/material";

const OperationContainer = styled(Container)(({ theme }) => ({
    maxWidth: "90%",
    height: "100%",
    [theme.breakpoints.down("lg")]: {
        padding: "0 0 0 0",
        maxWidth: "95%"
    },
    [theme.breakpoints.down("md")]: {
        padding: "0 0 0 0",
        maxWidth: "100%"
    }
}));

export default OperationContainer