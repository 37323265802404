import React, { Suspense, useContext, useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Drawer, Grid, IconButton, styled, Theme, useMediaQuery } from '@mui/material'
import Menu from '@mui/icons-material/Menu';
import Logo from '../Logo/Logo'
import { NavigationContext } from './store/context';
import { setSettingPage } from './store/reducer';
import { ActionType } from './store/actions';
import { THEME } from '../../config';
import { useHistory, useLocation } from 'react-router-dom';
import { SettingType } from '../../containers/Settings/Settings';
import MenuItem from './MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OperationModalityStatus from '../Operations/OperationModalityStatus/OperationModalityStatus';

const ReactiveLogoContainer = styled(Grid)(({ theme }) => ({
    minHeight: "inherit",
    height: "inherit",
    padding: "0.5rem 0",
    flex: 1,
    [theme.breakpoints.down("md")]: {
        padding: "0"
    }
}));

const ReactiveMenuContainer = styled(Grid)(() => ({
    minHeight: "inherit",
    height: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly"
}));

const ReactiveNavigationContent = styled(Grid)(({ theme }) => ({
    height: "inherit",
    minHeight: "inherit",
    justifyContent: "space-around",
    alignItems: "flex-start",
    flexWrap: "nowrap",
    [theme.breakpoints.down("sm")]: {
        padding: "0"
    }
}));

const ReactiveNavigationBar = styled(Box)(({ theme }) => ({
    minHeight: "50px",
    height: "7vh",
    width: "calc(100% + calc((100vw - 100%)))",
    marginLeft: "calc((100vw - 100%) * -1)",
    marginRight: "calc((100vw - 100%) * -1)",
    paddingLeft: "calc((100vw - 100%) / 2)",
    zIndex: 11,
    top: 0,
    position: "sticky",
    background: "#f8f8f8",
    borderBottom: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "#dce1e6",
    display: "block",
    [theme.breakpoints.down("md")]: {
        height: "5vh",
    }
}));

interface INavigationProps {
    settingsOverride?: boolean
}

const Navigation: React.FC<INavigationProps> = (props) => {

    const { state, dispatch } = useContext(NavigationContext)
    const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const [mobileDrawer, setMobileDrawer] = useState(false)
    const [drawerAccordion, setDrawerAccordion] = useState(false)
    const [optionsActive, setOptionsActive] = useState(true)
    const [developerOptionsActive, setDeveloperOptionsActive] = useState(false)
    const [operationStatusBar, setOperationStatusBar] = useState(false)
    const history = useHistory();
    const { pathname } = useLocation();

    useEffect(() => {
        dispatch({ type: ActionType.ToggleMobileMenu, toggle: matches })
    }, [matches, dispatch])

    useEffect(() => {
        setOptionsActive(state.optionsActive)
    }, [state.optionsActive])

    useEffect(() => {
        setDeveloperOptionsActive(state.developerOptions)
    }, [state.developerOptions])

    useEffect(() => {
        if (pathname === "/enroll" || pathname === "/verify" || pathname === "/verifyfromqrcode" || pathname === "/check") {
            setOperationStatusBar(true)
        } else {
            setOperationStatusBar(false)
        }
    }, [pathname])
    
    const handleMenuItemClick = (route: string, drawer?: boolean, settingType?: SettingType) => {
        history.push(route)
        if (drawer) {
            setMobileDrawer(false)
        }
        if (settingType) {
            dispatch(setSettingPage(settingType))
        }
    }

    const getAccordion = () => {
        return (
            <Accordion
                expanded={drawerAccordion}
                onChange={() => setDrawerAccordion(!drawerAccordion)}
                style={{
                    boxShadow: "none",
                    borderRadius: "0px",
                    borderBottom: "solid",
                    borderBottomWidth: "1px",
                    borderBottomColor: "#dce1e6"
                }}
            >
                <AccordionSummary
                    expandIcon={
                        <ExpandMoreIcon fontSize='medium' style={{color: THEME.palette.primary.main}}/>
                    }
                    id="Accordion"
                    style={{padding: "0px", borderBottomColor: "#a8a8a8", borderBottomStyle: "solid", borderBottomWidth: "0px", margin: "0 0 0 0"}}
                >
                    <MenuItem name="Settings" disableRipple />
                </AccordionSummary>
                <AccordionDetails sx={{display: "table-caption", padding: "0px"}}>
                    <MenuItem name="Capturing" sub onClick={() => handleMenuItemClick("/settings", true, SettingType.CAPTURING)}/>
                    <MenuItem name="Verification" sub onClick={() => handleMenuItemClick("/settings", true, SettingType.VERIFICATION)}/>
                    <MenuItem name="Liveness" sub onClick={() => handleMenuItemClick("/settings", true, SettingType.LIVENESS)}/>
                    <MenuItem name="ICAO" sub onClick={() => handleMenuItemClick("/settings", true, SettingType.ICAO)}/>
                    <MenuItem name="Management" sub onClick={() => handleMenuItemClick("/settings", true, SettingType.MANAGEMENT)}/>
                    {developerOptionsActive ? <MenuItem name="Developer" sub onClick={() => handleMenuItemClick("/settings", true, SettingType.DEVELOPER)}/> : null }
                    <MenuItem name="Reset" sub onClick={() => handleMenuItemClick("/settings", true, SettingType.RESET)}/>
                    <MenuItem name="About" sub onClick={() => handleMenuItemClick("/settings", true, SettingType.ABOUT)} />
                </AccordionDetails>
            </Accordion>
        )
    }

    const navPicker = () => {
        if (!optionsActive) {
            return (<></>)
        }
        if (state.isMobile) {
            return (
                <IconButton sx={{ color: THEME.palette.primary.main }} onClick={() => setMobileDrawer(true)}>
                    <Menu fontSize="large" />
                </IconButton>
            )
        } else {
            return (
                <React.Fragment>
                    <MenuItem name="Operations" onClick={() => handleMenuItemClick("/")} selected={pathname === "/"}/>
                    {props.settingsOverride? 
                        <MenuItem name="Settings" onClick={() => handleMenuItemClick("/settings")} selected={pathname === "/settings"}/>
                        :
                        <MenuItem name="Info" onClick={() => handleMenuItemClick("/info")} selected={pathname === "/info"}/>
                    }
                    
                </React.Fragment>
            )
        }
    }

    const getDrawer = () => {
        return (
            <Drawer
                anchor="right"
                open={mobileDrawer}
                onClose={() => setMobileDrawer(false)}
            >
                <MenuItem name="Operations" onClick={() => handleMenuItemClick("/", true)} slightBorder />
                {props.settingsOverride?
                    getAccordion()
                    :
                    <MenuItem name="Info" onClick={() => handleMenuItemClick("/info", true)} />
                }
            </Drawer>
        )
    } 

    return (
        <>
            {getDrawer()}
            <ReactiveNavigationBar>
                <ReactiveNavigationContent container spacing={0}>
                    <ReactiveLogoContainer xs={(pathname === "/enroll" || pathname === "/verify" || pathname === "/check" || pathname === "/verifyfromqrcode")? 2 : 10} md={4} item>
                        <Logo nav/>
                    </ReactiveLogoContainer>
                    <Grid xs={(pathname === "/enroll" || pathname === "/verify" || pathname === "/check" || pathname === "/verifyfromqrcode")? 8 : 0} md={4} item style={{height: "100%", justifyContent: "center", alignItems: "center"}} >
                        <OperationModalityStatus active={operationStatusBar} />
                    </Grid>
                    <ReactiveMenuContainer xs={2} md={4} style={{justifyContent: "flex-end"}} item>
                        <Suspense fallback={null}>
                            {navPicker()}
                        </Suspense>
                    </ReactiveMenuContainer>
                </ReactiveNavigationContent>
            </ReactiveNavigationBar>
        </>
    )
}

export default Navigation