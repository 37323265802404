import { styled } from "@mui/material";

const BiometricDiv = styled('div')(({ theme }) => ({
    borderRadius: "5px",
    height: "100%",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    alignContent: "center",
    overflow: "hidden",
}));

export default BiometricDiv