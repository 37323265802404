import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import React from "react";

const useStyles = makeStyles(() => ({
    button: {
        width: "100%",
        height: "6rem",
        borderRadius: "15px",
        display: "block",
        margin: "auto",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        textTransform: "none",
        fontSize: "1.25rem",
        fontWeight: 400,
    }
}));

interface IOperationButtonProps {
    onClick: any,
    title: string,
    disable?: boolean
}

const OperationButton: React.FC<IOperationButtonProps> = (props) => {
    const classes = useStyles();

    return(
        <Button className={classes.button} disabled={props.disable} onClick={props.onClick} title={props.title} color="primary" variant="contained">
            {props.title}
        </Button>
    );
}

export default OperationButton;