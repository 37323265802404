import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../store/context";
import { IOperationsStatus } from "../../types/FaceVerification";
import { setOperationState } from "../../store/reducer";
import { Grid } from "@mui/material";
import React from "react";
import { RefVideoStream } from "../VideoStream/BiometricFaceStream";
import { useHistory } from "react-router-dom";
import OperationContainer from "../../components/Operations/styled/OperationContainer";
import OperationTitle from "../../components/Operations/styled/OperationTitle";
import FaceOperation from "../Enroll/Modalities/FaceOperation";
import VoiceOperation from "../Enroll/Modalities/VoiceOperation";
import Overview from "../Overview/Overview";
import { ModalityType } from "../Enroll/Enroll";
import VerifyFromQrCodeSideBar from "./components/VerifyFromQrCodeSideBar";
import { ModalityStateStatus } from "../../store/actions";
import QrStreamOperation from "../Enroll/Modalities/QrStreamOperation";

const VerifyFromQrCode: React.FC = () => {

    const { state, dispatch } = useContext(AppContext)
    const childRef = React.useRef<RefVideoStream>(null)
    const [checkState, setCheckState] = useState<IOperationsStatus>(IOperationsStatus.IDLE);
    const history = useHistory()
    const [faceOperationUUID, setFaceOperationUUID] = useState("");
    const [voiceOperationUUID, setVoiceOperationUUID] = useState("");
    const [activeModality, setActiveModality] = useState<ModalityType | null>(null);

    useEffect(() => {
        if (state.activeModalities.qrScan) {
            setActiveModality(prev => {
                if (prev == null) {
                    return ModalityType.QR_SCAN
                } else {
                    return prev
                }
            })
        }
        if (state.activeModalities.faceModality) {
            setActiveModality(prev => {
                if (prev == null || ModalityType.QR_SCAN) {
                    return ModalityType.FACE_MODALITY
                } else {
                    return prev
                }
            })
        }
        if (state.activeModalities.voiceModality) {
            setActiveModality(prev => {
                if (prev == null || ModalityType.QR_SCAN) {
                    return ModalityType.VOICE_MODALITY
                } else {
                    return prev
                }
            })
        }
    }, [state.activeModalities.faceModality,state.activeModalities.voiceModality, history, state.activeModalities.qrScan])

    const getName = (): string => {
        switch (activeModality) {
            case ModalityType.QR_SCAN:
                return "Scan QR code"
            case ModalityType.FACE_MODALITY:
                return "Face verification"
            case ModalityType.VOICE_MODALITY:
                return "Voice verification"
            case ModalityType.OVERVIEW:
                return "Overview"
            default: 
                return "Verification"
        }
    }

    const getNextModality = () => {
        let index = -1
        const operationState = state.operationState.modalityState.map((s, i) => {
            if (s.state === ModalityStateStatus.ACTIVE) {
                index = i + 1;
                return {
                    modality: s.modality,
                    state: ModalityStateStatus.DONE
                }
            }
            if (i === index) {
                return {
                    modality: s.modality,
                    state: ModalityStateStatus.ACTIVE
                }
            }
            return s
        })
        dispatch(setOperationState({subjectId: state.operationState.subjectId, modalityState: operationState}))
        if (activeModality === ModalityType.FACE_MODALITY) {
            if (state.activeModalities.voiceModality) {
                setActiveModality(ModalityType.VOICE_MODALITY)
                setCheckState(IOperationsStatus.IDLE)
            } else {
                setActiveModality(ModalityType.OVERVIEW)
                setCheckState(IOperationsStatus.IDLE)
            }
        } 
        if (activeModality === ModalityType.VOICE_MODALITY) {
            setActiveModality(ModalityType.OVERVIEW)
            setCheckState(IOperationsStatus.IDLE)
        }
    }

    const getActiveState = () => {
        switch (activeModality) {
            case ModalityType.QR_SCAN:
                return (
                    <QrStreamOperation
                        setCheckState={setCheckState}
                        childRef={childRef}    
                    />
                )
            case ModalityType.FACE_MODALITY:
                return (
                    <FaceOperation
                        setCheckState={setCheckState}
                        childRef={childRef}
                        setOperationUUID={setFaceOperationUUID}
                    />
                )
            case ModalityType.VOICE_MODALITY:
                return (
                    <VoiceOperation
                        setCheckState={setCheckState}
                        childRef={childRef}
                        setOperationUUID={setVoiceOperationUUID}
                    />
                )
            case ModalityType.OVERVIEW:
                return  (
                    <Overview
                        faceOperationUUID={faceOperationUUID}
                        voiceOperationUUID={voiceOperationUUID}
                    />
                )
        }
    }
    
    return (
        <OperationContainer maxWidth={false}>
            <Grid container>
                <OperationTitle>
                    {/* Verification from QR code */}
                    {getName()}
                </OperationTitle>
                {getActiveState()}
                <VerifyFromQrCodeSideBar
                    activeModality={activeModality}
                    checkState={checkState}
                    setCheckState={setCheckState}
                    getNextModality={getNextModality}
                    childRef={childRef}
                />
            </Grid>
        </OperationContainer>
    );
}

export default VerifyFromQrCode
