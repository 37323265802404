import React, { useContext, useEffect } from "react"
import { Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import GeneralGuidelines from "../../components/Home/GeneralGuidelines";
import OperationsButtons from "../../components/Home/OperationsButtons";
import { AppContext } from "../../store/context";
import { showOperationSummary } from "../../store/reducer";
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(() => ({
    mainContainer: {
        paddingBottom: useTheme().spacing(5),
    },
    typographyGrid: {
        marginTop: useTheme().spacing(3)
    },
    intoduction: {
        fontWeight: 600,
        fontSize: "2.125rem"
    },
    introductionText: {
        fontSize: "1rem"
    },
    generalGuidelines: {
        fontWeight: 500,
        fontSize: "1.5rem"
    },
    operationsGrid: {
        marginBottom: useTheme().spacing(3)
    }
}));

const Home: React.FC = () => {

    const classes = useStyles();
    const { state, dispatch } = useContext(AppContext)

    useEffect(() => {
        const clearOperationsummary = () => {
            if (state.showOperationSummary.length > 0)
                dispatch(showOperationSummary(""))
        }
        clearOperationsummary();
    }, [state.showOperationSummary, dispatch])

    return (
        <Container className={classes.mainContainer}>
            <Grid container spacing={3}>
                <Grid item md={8} xs={12} className={classes.typographyGrid}>
                    <Typography className={classes.intoduction}>
                        Welcome!
                    </Typography>
                    <Typography className={classes.introductionText}>
                        Ready to get started? Choose one of the following operations:
                    </Typography>
                </Grid>
                <Grid item md={12} xs={12} className={classes.operationsGrid}>
                    <OperationsButtons/>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Typography className={classes.generalGuidelines}>
                        General guidelines
                    </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                    <GeneralGuidelines />
                </Grid>
            </Grid>
        </Container>
    );
}

export default Home;
