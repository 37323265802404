import { Dialog, DialogTitle, Grid, Button, TextField } from "@mui/material"
import { makeStyles } from "@mui/styles";
import { THEME } from "../../../config";
import { IModality, IOperationState, ModalityStateStatus } from "../../../store/actions";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../store/context";
import { setActiveModalities, setOperationState } from "../../../store/reducer";
import { useHistory } from "react-router-dom";
import { ModalityType } from "../Enroll";
import { SubjectsAPI } from "../../../config/management-api";
import CheckboxBoxContainer from "./CheckboxBoxContainer";

const useStyles = makeStyles(() => ({
    dialog: {

    },
    title: {
        fontSize: "1.25rem",
        backgroundColor: THEME.palette.background.default
    },
    text: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
    },
    gridIcon: {
        textAlign: "right",
        fontSize: "1.2rem",
        color: THEME.palette.primary.main
    },
    gridContent: {
        paddingTop: "1rem",
        paddingRight: "1rem",
        paddingLeft: "1rem",
    },
    gridText: {
        alignSelf: "center",
    },
    gridAutoComplete: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingTop: "1rem"
    },
    buttonCancel: {
        fontSize: "1.125rem",
        width: "95%",
        textTransform: "none"
    },
    buttonConfirm: {
        fontSize: "1.125rem",
        width: "95%",
        textTransform: "none"
    },
    gridButton: {
        margin: "1rem",
        textAlign: "center",
        marginTop: "2rem",
    },
    radio: {
        color: THEME.palette.primary.main,
    }
}))

interface IEnrollDialog {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const EnrollDialog: React.FC<IEnrollDialog> = (props) => {

    const classes = useStyles();
    const [active, setActive] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState<IModality>({faceModality: false, voiceModality: false})
    const [disabledOptions, setDisabledOptions] = useState<IModality>({faceModality: false, voiceModality: false})
    const {state, dispatch} = useContext(AppContext);
    const [error, setError] = useState("");
    const [subjectId, setSubjectId] = useState("")
    const [isSubjectIdGood, setIsSubjectIdGood] = useState(false)
    const history = useHistory()
    const [loadingNext, setLoadingNext] = useState(false)

    useEffect(() => {
        if (selectedOptions.faceModality !== false || selectedOptions.voiceModality !== false) {
            setActive(true)
        } else {
            setActive(false)
        }
    }, [setActive, selectedOptions])

    useEffect(() => {
        dispatch(setActiveModalities({faceModality: false, voiceModality: false}))
    }, [dispatch])

    const handleClose = (e?: any) => {
        props.setOpen(false)
        setTimeout(() => {
            setIsSubjectIdGood(false)
            setError("")
            setSubjectId("")
            setSelectedOptions({faceModality: false, voiceModality: false})
        }, 300);
    }

    const handleCancel = () => {
        handleClose()
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (isSubjectIdGood) {
                handleStart()
            } else {
                handleNext()
            }
        }   
    }

    const handleNext = async () => {
        if (!subjectId || /^\s*$/.test(subjectId)) {
            setError("SubjectID cannot be empty")
            return;
        }
        setLoadingNext(true)
        let subjectIdRequest = ""
        if (state.prefix !== null) {
            subjectIdRequest = state.prefix + "." + subjectId;
        } else {
            subjectIdRequest = subjectId
        }
        SubjectsAPI.getSubjectsEnrolledModalities({subjectId: subjectIdRequest}).then((modalitiesResult) => {
            const data = modalitiesResult.data
            setDisabledOptions({faceModality: data.faceModality ?? false, voiceModality: data.voiceModality ?? false})
            setIsSubjectIdGood(true)
            setLoadingNext(false)
        }).catch((e: any) => {
            setError("Could not get modalities")
            console.error(e)
            setLoadingNext(false)
            return; 
        })
    }

    const handleStart = () => {
        let subjectIdRequest = ""
        if (state.prefix !== null) {
            subjectIdRequest = state.prefix + "." + subjectId;
        } else {
            subjectIdRequest = subjectId
        }
        let operationStatus: IOperationState = {subjectId: subjectIdRequest, modalityState: []}
        if (selectedOptions.faceModality) {
            operationStatus.modalityState.push({modality: ModalityType.FACE_MODALITY, state: ModalityStateStatus.ACTIVE})
        }
        if (selectedOptions.voiceModality) {
            if (operationStatus.modalityState.length === 0) {
                operationStatus.modalityState.push({modality: ModalityType.VOICE_MODALITY, state: ModalityStateStatus.ACTIVE})

            } else {
                operationStatus.modalityState.push({modality: ModalityType.VOICE_MODALITY, state: ModalityStateStatus.TO_BE_DONE})
            }
        }
        operationStatus.modalityState.push({modality: ModalityType.OVERVIEW, state: ModalityStateStatus.TO_BE_DONE})
        dispatch(setOperationState(operationStatus))
        dispatch(setActiveModalities(selectedOptions))
        setTimeout(() => {
            history.push("/enroll")
            handleClose()
        }, 50)
    }

    const getConfirmButton = () => {
        if (!isSubjectIdGood) {
            return (
                <Button
                    className={classes.buttonConfirm}
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={(subjectId.length === 0 || error.length !== 0) || loadingNext}
                >
                    Next
                </Button>
            )
        } else {
            return (
                <Button className={classes.buttonConfirm} variant="contained" color="primary" onClick={handleStart} disabled={!active}>
                    Start
                </Button>
            )
        }
    }

    const getDialogBody = () => {
        if (!isSubjectIdGood) {
            return (
                <TextField
                    autoFocus
                    margin="normal"
                    required
                    error={error ? true : false}
                    helperText={error}
                    fullWidth
                    id="subjectId"
                    name="subjectId"
                    label="Subject ID"
                    color="primary"
                    value={subjectId}
                    onChange={(event) => setSubjectId(event.target.value)}
                    onKeyDown={handleKeyDown}
                />
            )
        } else {
            return (
                <CheckboxBoxContainer
                    faceModality={selectedOptions.faceModality}
                    voiceModality={selectedOptions.voiceModality}
                    faceModalityDisabled={disabledOptions.faceModality}
                    voiceModalityDisabled={disabledOptions.voiceModality}
                    setFaceModality={(c) => setSelectedOptions({...selectedOptions, faceModality: c})}
                    setVoiceModality={(c) => setSelectedOptions({...selectedOptions, voiceModality: c})}
                />
            )
        }
    }

    return (
        <Dialog
            onClose={(e) => handleClose(e)}
            open={props.open}
            maxWidth="xs"
            className={classes.dialog}
        >
            <DialogTitle className={classes.title}>Enroll</DialogTitle>
            <Grid container>
                <Grid container className={classes.gridContent}>
                    {getDialogBody()}
                </Grid>
                <Grid container className={classes.gridButton}>
                    <Grid item xs={6} >
                        <Button className={classes.buttonCancel} variant="outlined" color="secondary" onClick={handleCancel}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6} >
                        {getConfirmButton()}
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default EnrollDialog